<template>
  <div class="p-2">
    <div class="row">
      <div class="col-12 col-md-3">
        <div
          class="text-center pt-0 border border-grey rounded-pill mb-4"
          style="width: 200px; height: 200px; margin: 0 auto; overflow: hidden"
          v-if="obj"
        >
          <file-upload
            :enable-title="false"
            :max-files="1"
            :value="obj.foto"
            :freeStyle="true"
            :photoIcon="true"
            @input="updateFoto"
            :enableEdit="obj.cod_cliente ? true : false"
          ></file-upload>
        </div>
        <b-btn
          v-if="fotoAlterado"
          @click="saveFoto"
          variant="success"
          block
        >
          Salvar Foto
        </b-btn>

        <div class="text-center bg-light textCadastro py-2">
          <div class="row my-4">
            <div class="col-4">
              Código <br />
              {{ obj && obj.cod_cliente }}
            </div>
            <div class="col-4">
              Cadastro <br />
              {{ obj && obj.dt_cadastro | moment("DD/MM/YYYY") }}
            </div>
            <div class="col-4">
              Saldo <br />
              {{ obj && obj.saldo | currency }}
            </div>
          </div>
        </div>
        <div class="menu-lateral">
          <div
            v-for="(mn, idx) in menuLista"
            :key="idx"
            :class="{ selected: mn.label == menuSelected }"
            @click="menuSelected = mn.label"
          >
            <i :class="mn.icon"></i> {{ mn.label }}
          </div>
        </div>
      </div>
      <div class="col-12 col-md-9">
        <div v-if="obj && menuSelected == 'Resumo'">
          <cliente-form-completo-dash :obj="obj"></cliente-form-completo-dash>
        </div>
        <div v-if="obj && menuSelected == 'Cadastro'">
          <cliente-form-completo-form
            :id="obj.cod_cliente"
            @openCadastro="
              (id) => {
                this.id = id;
                this.carregar();
                if (this.onModal) {
                  this.$emit('closeAndOpen', id);
                }
              }
            "
            @close="close"
          ></cliente-form-completo-form>
        </div>
        <div v-if="obj && obj.cod_cliente && menuSelected == 'Comandas'">
          <cliente-comandas :cod_cliente="obj.cod_cliente"></cliente-comandas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from "../common/FileUpload.vue";
import validador from "../../utils/validador";
import cep from "@/utils/cep";
import ClientesLib from "@/libs/ClientesLib";
import moment from "moment";
import ClienteFormCompletoDash from "./ClienteFormCompletoDash.vue";
import ClienteFormCompletoForm from "./ClienteFormCompletoForm.vue";
import ClienteComandas from './ClienteComandas.vue';
export default {
  components: {
    FileUpload,
    ClienteFormCompletoDash,
    ClienteFormCompletoForm,
    ClienteComandas,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    enabled: {
      type: Boolean,
      default: true,
    },
    onModal: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.carregar();
  },
  data() {
    return {
      obj: null,
      menuSelected: "Resumo",
      menus: [
        {
          label: "Resumo",
          icon: "fa fa-chart-pie mr-2",
          onInset: false,
        },
        {
          label: "Cadastro",
          icon: "far fa-clipboard mr-2",
          onInset: true,
        },
        {
          label: "Comandas",
          icon: "fa fa-barcode mr-2",
          onInset: false,
        },
      ],
      cpfValido: null,
      lista_ufs: [],
      lista_cidades: [],
      options_cidades: [],
      loadingCEP: false,
      listBairros: this.$store.state.enderecos.bairros.map((b) => {
        return b.bairro;
      }),
      listLogradouros: this.$store.state.enderecos.logradouros.map((b) => {
        return b.logradouro;
      }),
      autoCompleteStyle: {
        vueSimpleSuggest: "position-relative",
        inputWrapper: "",
        defaultInput: "form-control inputMenor",
        suggestItem: "list-group-item",
        suggestions: "list-group",
        maxSuggestions: 2,
      },
      loaded: false,
      fotoAlterado: null,
    };
  },
  watch: {
    "obj.foto": {
      handler: function () {},
      deep: true,
    },
  },
  computed: {
    menuLista() {
      return this.menus;
    },
  },
  methods: {
    async saveFoto() {
      if (this.fotoAlterado) {
        await ClientesLib.salvarCliente(this.obj);
        this.$swal({
          title: "Foto salva com sucesso!",
          icon: "success",
          showConfirmButton: false,
          toast: true,
          timer: 1000,
          position: "top-end",
        });
        this.fotoAlterado = false;
      }
    },
    async updateFoto(imgList) {
      if (!this.loaded || !this.obj.foto) return;
      console.log("imgList", imgList);

      if (this.obj.cod_cliente) {
        if (imgList.length == 0 || imgList[0].status == 2)
          this.fotoAlterado = imgList;
      } else {
        this.obj.foto = null;
        imgList = null;
        this.$swal({
          title: "Cliente ainda não cadastrado!",
          icon: "error",
          showConfirmButton: false,
          toast: true,
          timer: 1000,
          position: "top-end",
        });
      }
    },
    close(idSel) {
      this.loaded = false;
      this.$emit("close", idSel);
    },
    async carregar() {
      if (this.id) {
        let res = await ClientesLib.getAllClientes({
          cod_cliente: this.id,
        });

        console.log("res clientes", res);
        if (res && res.success) {
          this.obj = res.data[0];
          if (!this.obj.foto) this.obj.foto = [];
          this.menuSelected = "Resumo";
          setTimeout(() => {
            this.loaded = true;
          }, 2000);
        }
      } else {
        this.menuSelected = "Cadastro";

        this.obj = {
          cod_cliente: null,
          Nome: "",
          Telefone: "",
          UF: "",
          Cidade: "",
          Logradouro: "",
          Numero: "",
          Bairro: "",
          Email: "",
          CGC: "",
          complemento: "",
          CEP: "",
          negativado: false,
          motivo_negativado: "",
          cod_empresa: null,
          dt_cadastro: moment().format("YYYY-MM-DD"),

          situacao: "Ativo",
          situacao_acesso: "Pendente",
          dt_nascimento: ``,
          sexo: "M",
          saldo: 0,
          cliente: 1,
          funcionario: 0,
          fornecedor: 0,
          foto: [],
        };
      }
    },
    validaCPF() {
      this.cpfValido = null;
      if (this.obj.CGC && this.obj.CGC != "") {
        if (
          !validador.string.validarCPF(this.obj.CGC) &&
          !validador.string.validarCNPJ(this.obj.CGC)
        ) {
          this.$swal(
            "CPF/CNPJ INVÁLIDO!",
            "CPF informado está incorreto!",
            "error"
          );
          this.cpfValido = false;
        } else {
          this.cpfValido = true;
        }
      }
    },
    async buscarCEP() {
      this.loadingCEP = true;
      try {
        const result = await cep.buscarCEP(this.obj.CEP);
        if (result && result.street) {
          this.obj.Bairro = result.neighborhood;
          this.obj.Logradouro = result.street;

          this.$forceUpdate();
        }
      } catch (err) {
        console.log("ERROR ON BUSCAR CEP");
      }
      this.loadingCEP = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.textCadastro {
  font-size: 0.7em;
  font-weight: bold;
}
.menu-lateral {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 4px;
  color: #333;
  width: 100%;

  div {
    font-size: 1.2em;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 3px;
    &:hover,
    &.selected {
      color: #88d63b;
      background-color: #f9f9f9;
    }
  }
}
</style>
