<template>
  <div
    class="cardapio-item"
    :class="{
      'item-indisponivel': !estaDisponivel,
      'item-selecionado': quantidade > 0,
    }"
  >
    <div class="item-container">
      <!-- Ícone do produto em vez de imagem -->
      <div class="item-icone">
        <div v-if="imagemProduto" class="imagem-wrapper">
          <img :src="imagemProduto" :alt="getNomeProduto" />
        </div>
        <div v-else class="icone-wrapper">
          <i class="fa fa-utensils"></i>
        </div>
        <div v-if="!estaDisponivel" class="item-indisponivel-overlay">
          <span>Indisponível</span>
        </div>
      </div>

      <!-- Informações do produto -->
      <div class="item-info">
        <!-- Usamos várias possibilidades de nomes para garantir compatibilidade -->
        <h3 class="item-nome">{{ getNomeProduto }}</h3>

        <!-- Descrição e ingredientes do produto -->
        <p class="item-descricao" v-if="getDescricaoProduto">{{ getDescricaoProduto }}</p>

        <!-- Listagem de ingredientes -->
        <p class="item-ingredientes" v-if="getIngredientes.length > 0">
          <strong>Ingredientes:</strong> {{ getIngredientes.join(", ") }}
        </p>

        <div class="item-preco">{{ formatarPreco(precoProduto) }}</div>
      </div>

      <!-- Controle de quantidade -->
      <div class="item-quantidade" v-if="estaDisponivel">
        <button
          v-if="quantidade > 0"
          class="btn-quantidade diminuir"
          @click="diminuirQuantidade"
          :disabled="!estaDisponivel"
        >
          <i class="fa fa-minus"></i>
        </button>

        <span v-if="quantidade > 0" class="quantidade-valor">{{ quantidade }}</span>

        <button
          class="btn-quantidade adicionar"
          @click="aumentarQuantidade"
          :disabled="!estaDisponivel"
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    produto: {
      type: Object,
      required: true,
    },
    empresa: {
      type: Number,
      required: false,
    },
    mesa: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      quantidade: 0,
    };
  },
  computed: {
    getNomeProduto() {
      // Verifica todas as possibilidades de propriedades para o nome do produto
      return (
        this.produto.Produto ||
        this.produto.nome ||
        this.produto.name ||
        "Produto sem nome"
      );
    },
    imagemProduto() {
      console.log(this.produto, "produto");
      return this.produto.imagens && this.produto.imagens.length > 0
        ? this.produto.imagens[0].fileThumbUrl
        : "";
    },
    getDescricaoProduto() {
      return (
        this.produto.Descricao || this.produto.descricao || this.produto.description || ""
      );
    },
    getIngredientes() {
      // Extrai os nomes dos ingredientes
      if (this.produto.ingredientes && Array.isArray(this.produto.ingredientes)) {
        return this.produto.ingredientes.map(
          (ing) => ing.ingrediente || ing.nome || ing.name || "Ingrediente"
        );
      }
      return [];
    },
    precoProduto() {
      // Lógica para obter o preço correto considerando todas as possibilidades
      if (this.produto.pricing && this.produto.pricing.length > 0) {
        return parseFloat(this.produto.pricing[0].price);
      }

      if (this.produto.precos && this.produto.precos.length > 0) {
        return parseFloat(
          this.produto.precos[0].preco_venda || this.produto.precos[0].inteira || 0
        );
      }

      // Considerar todas as possíveis propriedades de preço
      return parseFloat(
        this.produto.Preco ||
          this.produto.preco ||
          this.produto.preco_venda ||
          this.produto.price ||
          0
      );
    },
    estaDisponivel() {
      return this.produto.ativo !== false && this.produto.inativo !== true;
    },
  },
  created() {
    // Para depuração
    console.log("[CardapioItem] Produto recebido:", {
      nome: this.getNomeProduto,
      preco: this.precoProduto,
      ingredientes: this.getIngredientes,
    });

    // Verificar se o produto já está no pedido atual
    if (this.$store.state.itensPedido && this.$store.state.itensPedido.length) {
      const itemExistente = this.$store.state.itensPedido.find(
        (item) => item.cod_produto === this.produto.cod_produto
      );

      if (itemExistente) {
        this.quantidade = itemExistente.quantidade;
      }
    }
  },
  methods: {
    aumentarQuantidade() {
      if (!this.estaDisponivel) return;

      this.quantidade++;
      this.emitirAlteracaoQuantidade();
    },
    diminuirQuantidade() {
      if (this.quantidade <= 0) return;

      this.quantidade--;
      this.emitirAlteracaoQuantidade();
    },
    emitirAlteracaoQuantidade() {
      this.$emit("quantidade-alterada", this.produto, this.quantidade);
    },
    formatarPreco(valor) {
      return `R$ ${valor.toFixed(2).replace(".", ",")}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.cardapio-item {
  background-color: white;
  border-radius: 10px;
  @media screen and (max-width: 575px) {
    border-radius: 0px;
    box-shadow: none;
  }
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.06);
  transition: transform 0.2s, box-shadow 0.2s, border-color 0.3s;
  // border: 1px solid #f0f0f0;
  border: 2px solid transparent;
  margin-top: 1px;

  &:hover {
    // transform: translateY(-3px);
    // box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
  }

  &.item-indisponivel {
    opacity: 0.7;
    filter: grayscale(0.5);
  }

  &.item-selecionado {
    border: 2px solid #d32f2f;
    margin: 2px;
    // box-shadow: 0 4px 12px rgba(211, 47, 47, 0.15);

    .item-nome {
      color: #d32f2f;
    }

    .item-preco {
      font-weight: 700;
    }
  }
}

.item-container {
  display: grid;
  grid-template-columns: 80px 1fr auto;
  gap: 15px;
  padding: 12px;

  @media (max-width: 576px) {
    gap: 10px;
  }
}

.item-icone {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  .imagem-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .icone-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
    color: #666;

    i {
      font-size: 32px;
    }
  }

  .item-indisponivel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-weight: 500;
    font-size: 12px;
  }
}

.item-info {
  min-width: 0; // Importante para text-overflow funcionar
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item-nome {
  margin: 0 0 5px;
  font-size: 16px;
  font-weight: 600;
  color: #333;
  overflow: hidden;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  transition: color 0.3s;
}

.item-descricao {
  margin: 0;
  font-size: 13px;
  color: #666;
  line-height: 1.4;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 5px;
}

.item-ingredientes {
  margin: 0 0 5px;
  font-size: 12px;
  color: #777;
  line-height: 1.3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-style: italic;
}

.item-preco {
  font-weight: 600;
  font-size: 15px;
  color: #d32f2f; /* Vermelho */
  transition: font-weight 0.3s;
}

.item-quantidade {
  display: flex;
  align-items: center;
  gap: 8px;
}

.btn-quantidade {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 12px;

  &.adicionar {
    background-color: #d32f2f; /* Vermelho */
    color: white;

    &:hover:not(:disabled) {
      background-color: #b71c1c; /* Vermelho mais escuro no hover */
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  &.diminuir {
    background-color: rgba(211, 47, 47, 0.1); /* Vermelho com transparência */
    color: #d32f2f; /* Vermelho */

    &:hover:not(:disabled) {
      background-color: rgba(211, 47, 47, 0.2); /* Vermelho com mais opacidade no hover */
    }

    &:disabled {
      background-color: #f0f0f0;
      color: #ccc;
      cursor: not-allowed;
    }
  }
}

.quantidade-valor {
  font-weight: 600;
  font-size: 16px;
  min-width: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .item-container {
    grid-template-columns: 70px 1fr auto;
  }

  .item-icone {
    width: 70px;
    height: 70px;
  }

  .item-nome {
    font-size: 15px;
  }

  .item-descricao {
    font-size: 12px;
    -webkit-line-clamp: 1;
  }

  .item-ingredientes {
    font-size: 11px;
    -webkit-line-clamp: 1;
  }
}
</style>
