import Vue from "vue";
import axios from "@/utils/axios";

const AuthModule = {
  namespaced: true,
  state: () => ({
    user: {},
    auth: false,
  }),
  //   mutations: {
  //     ATUALIZA_PRODUTO(state) {
  //       console.log(state);
  //     },
  //   },
  actions: {
    logout: async ({ state }) => {
      localStorage.clear();
      await axios
        .get("auth/logout", {})
        .then(() => {})
        .catch(() => {});
      state.user = {};
      return true;
    },
    getUser: ({ state }, payload) => {
      state.user = payload;
    },
    auth: async ({ state, dispatch }, payload) => {
      return await axios
        .post(`/auth`, payload)
        .then(async (result) => {
          state.user.token = result.data.token;
          localStorage.setItem("token", state.user.token);
          state.auth = true;
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${state.user.token}`;
          dispatch("isAuthenticated").then((res) => {
            if (res) {
              return { success: true, user: result.data };
            } else {
              // console.log(err);
              return { success: false, user: null };
            }
          });
          return { success: true, user: result.data };
        })
        .catch((error) => {
          console.log("error", error);
          return false;
        });
    },
    isAuthenticated: async ({ rootState, state, dispatch }) => {
      rootState.loading = true;

      state.user.token = localStorage.getItem("token");
      if (
        typeof state.user.token == "undefined" ||
        state.user.token == null ||
        state.user.token == ""
      ) {
        state.auth = false;
        return false;
      } else {
        Vue.prototype.$socket.disconnect();
        Vue.prototype.$socket.io.engine.opts.query.token = state.user.token;
        Vue.prototype.$socket.io.opts.query = { token: state.user.token };
        Vue.prototype.$socket.connect();
        if (
          typeof state.user != "undefined" &&
          typeof state.user.perfil != "undefined"
        ) {
          state.auth = true;
          return true;
        } else {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${state.user.token}`;
          return await axios
            .post("auth/me", { tipo: "usuario" })
            .then(async (result) => {
              let nivel =
                state.user.tipo == "Administrador"
                  ? 0
                  : state.user.tipo == "Gerente"
                  ? /*||
                    state.user.tipo == "Gerente Caixa" ||
                    state.user.tipo == "Gerente Moto"*/
                    1
                  : 2;
              state.user = { ...result.data, ...state.user, nivel };
              // console.log('user', state.user)

              await dispatch("atualizaEmpresas", result.data);

              rootState.menu = require("../../Menu").default.filter(
                (x) =>
                  x.permissao.includes("*") ||
                  x.permissao.includes(result.data.tipo)
              );

              // console.log("ends", rootState.enderecos);]
              if (!rootState.cardapio || rootState.cardapio.length <= 0) {
                await dispatch("atualizarCardapio");
              }
              // console.log("user ok");
              return true;
            })
            .catch((err) => {
              console.log(err);
              //console.log("user not");

              state.user = {};
              localStorage.clear();
              return false;
            });
        }
      }
    },
    atualizaEmpresas: async ({ rootState, state }, result) => {
      await axios.post("/auth/empresa", { tipo: "usuario" }).then((data) => {
        rootState.empresas = data.data;
      });
      if (result) {
        await axios
          .get("/caixas", {
            params: { empresa_id: result.cod_empresa },
          })
          .then((data) => {
            rootState.caixas = data.data;
          });

        rootState.caixa_ativo = rootState.caixas.find(
          (x) => x.cod_caixa == result.cod_caixa
        );
      }
      rootState.empresa_ativa =
        rootState.empresas &&
        rootState.empresas.cod_empresa == state.user.cod_empresa
          ? rootState.empresas
          : rootState.empresas && rootState.empresas.empresas
          ? rootState.empresas.empresas.find(
              (x) => x.cod_empresa == state.user.cod_empresa
            )
          : null;

      //console.log('user', state.user, rootState.empresa_ativa);

      //atualiza endereços
      let carregaEnderecos = false;
      rootState.enderecos = localStorage.getItem("enderecos");
      if (rootState.enderecos) {
        try {
          rootState.enderecos = JSON.parse(rootState.enderecos);
        } catch {
          carregaEnderecos = true;
        }
      } else {
        carregaEnderecos = true;
      }
      if (carregaEnderecos) {
        axios.get("/enderecos").then((x) => {
          rootState.enderecos = x.data;
          localStorage.setItem(
            "enderecos",
            JSON.stringify(rootState.enderecos)
          );
        });
      }
    },
    atualizarCardapio: async ({ rootState }, wait = false) => {
      console.log("start atu cardapio", rootState.empresas, wait);

      if(rootState.loadingCardapio){
        return;
      }

      rootState.loadingCardapio = true;

      // rootState.loading = true;
      let writeLocal = false;
      if (rootState.auth.user.cod_empresa) {
        if (rootState.empresas) {
          let empresas = [rootState.empresas, ...rootState.empresas.empresas];

          let hasEmp = empresas.find(
            (x) => x.cod_empresa == rootState.auth.user.cod_empresa
          );
          if (hasEmp) {
            if (hasEmp.cod_empresa_pai) {
              hasEmp = empresas.find(
                (x) => x.cod_empresa == hasEmp.cod_empresa_pai
              );
            }
            let hasLocalVersion = await localStorage.getItem("cardapio_versao");
            if (hasLocalVersion && hasLocalVersion != "") {
              console.log(
                "local version",
                hasLocalVersion,
                hasEmp.cardapio_versao
              );
              if (hasLocalVersion == hasEmp.cardapio_versao && 1==2) {
                console.log("LOCAL CARDAPIO");
                // localStorage.setItem("cardapio_versao", hasEmp.cardapio_versao);
                let cardapioLocal = localStorage.getItem(
                  "cardapio_" + rootState.auth.user.cod_empresa
                );
                if (cardapioLocal && cardapioLocal.length != "") {
                  cardapioLocal = JSON.parse(cardapioLocal);
                  rootState.cardapio = cardapioLocal;
                  writeLocal = false;
                } else {
                  writeLocal = true;
                  localStorage.setItem(
                    "cardapio_versao",
                    hasEmp.cardapio_versao
                  );
                }
              } else {
                writeLocal = true;
                localStorage.setItem("cardapio_versao", hasEmp.cardapio_versao);
              }
            } else {
              localStorage.setItem("cardapio_versao", hasEmp.cardapio_versao);

              writeLocal = true;
            }
          }
        }

        if (writeLocal) {
          console.log("Buscando cardapio da base");
          const setcard = (data) => {
            rootState.cardapio = data.data.map((it) => {
              if (it.imagens && typeof it.imagens == "string") {
                it.imagens = JSON.parse(it.imagens);
              }
              return {
                ...it,
                tabela:
                  it.preco && typeof it.preco == "string"
                    ? it.preco.split("|")
                    : null,
              };
            });
            console.log(123, rootState.cardapio);
            localStorage.setItem(
              "cardapio_" + rootState.auth.user.cod_empresa,
              JSON.stringify(rootState.cardapio)
            );
          };
          if (wait) {
            await axios
              .get(`/cardapio/${rootState.auth.user.cod_empresa}`)
              .then(setcard)
              .catch((err) => {
                console.log("err cardapio", err);
              });
          } else {
            axios
              .get(`/cardapio/${rootState.auth.user.cod_empresa}`)
              .then(setcard)
              .catch((err) => {
                console.log("err cardapio", err);
              });
          }
        }
        await axios
          .get(`/ingredientes/${rootState.auth.user.cod_empresa}`)
          .then((data) => {
            // console.log("ingredites atualizado");
            rootState.ingredientes = data.data;
          })
          .catch(() => {
            console.log("err ingredientes");
          });

        await axios
          .get(`/tipos_preco/${rootState.auth.user.cod_empresa}`)
          .then((data) => {
            // console.log("tipos_preco atualizado");
            rootState.tipos_preco = data.data;
          })
          .catch(() => {
            console.log("err tipos_preco");
          });
        // rootState.loading = false;
      }
      // console.log(rootState);
      rootState.loadingCardapio = false;
    },
    apagarProduto({ rootState }, { cod_grupo, cod_produto }) {
      console.log("apagar produto", cod_grupo, cod_produto);
      const cardapio = rootState.cardapio.reduce((ret, vl) => {
        ret.push(...vl.produtos);
        return ret;
      }, []);
      const produtoIndex = cardapio.findIndex(
        (p) => p.cod_produto == cod_produto
      );

      // console.log(produtoIndex);
      // console.log(cardapio.produtos[produtoIndex]);

      if (produtoIndex >= 0) cardapio.splice(produtoIndex, 1);
      //  console.log(rootState);
    },
    atualizarProduto: async ({ rootState }, _produto) => {
      //  console.log(produto);
      if (rootState.cardapio.length > 0 && _produto) {
        // console.log(_produto);
        // console.log("cardapio", rootState.cardapio);
        let cardapio;
        let grupo = (
          await axios.get("produtosGrupos/get", {
            params: { cod_grupo: _produto.cod_grupo },
          })
        ).data;
        grupo = grupo[0];
        //console.log(grupo);
        if (grupo.tipo == "PIZZA") {
          cardapio = rootState.cardapio.find((c) => c.tipo == "PIZZA");
        } else {
          cardapio = rootState.cardapio.find(
            (c) => c.cod_grupo == _produto.cod_grupo
          );
        }

        // console.log("cardapio encontrado", cardapio);

        if (cardapio) {
          const produto = cardapio.produtos.find(
            (p) => p.cod_produto == _produto.cod_produto
          );
          //console.log(produto, "produto encontrado");
          Object.assign(produto, _produto);
        }
      }

      //  console.log(rootState);
    },
  },
};

export default AuthModule;
