const hasViewMesas = window.localStorage.getItem("viewMesas")
  ? window.localStorage.getItem("viewMesas")
  : "mesas";
const menu = [
  // {
  //   id: -10,
  //   nome: "Notificações",
  //   link: "/notificacoes",
  //   icon: "fa fa-bell mb-2",
  //   iconType: "fa",
  //   notifications: true,
  //   permissao: ["*"],
  //   exibe: true,
  // },
  {
    id: 0,
    nome: "Dashboard",
    link: "/dash",
    icon: "combo_chart_52px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente"],
    exibe: true,
  },
  {
    id: 0.1,
    nome: "Clientes",
    link: "/clientes",
    icon: "fa fa-users",
    iconType: "fa",
    permissao: ["Administrador", "Gerente", "Caixa"],
    exibe: true,
  },
  // {
  //   id: 1,
  //   nome: "Caixas",
  //   link: "/caixas",
  //   icon: "cash_register_40px.png",
  //   iconType: "png",
  //   permissao: [
  //     "Administrador",
  //     "Gerente Caixa",
  //     "Gerente",
  //     "Caixa",
  //     "Usuario",
  //   ],
  //   exibe: true,
  // },
  {
    id: 1.1,
    nome: "Caixa",
    link: "/caixa",
    icon: "cash_register_40px.png",
    iconType: "png",
    permissao: [
      "Administrador",
      "Gerente Caixa",
      "Gerente",
      "Caixa",
      "Usuario",
    ],
    exibe: true,
  },

  {
    id: 3,
    nome: "Pedidos",
    link: "/pedidos",
    icon: "icons8_buying_40px.png",
    iconType: "png",
    permissao: [
      "Administrador",
      "Gerente Moto",
      "Gerente Caixa",
      "Gerente",
      "Caixa",
      "Usuario",
    ],
    exibe: true,
  },

  {
    id: 4,
    nome: "PEDIR",
    link: "/pedido",
    icon: "add_shopping_cart_40px.png",
    iconType: "png",
    permissao: [
      "Administrador",
      "Gerente Moto",
      "Gerente Caixa",
      "Gerente",
      "Caixa",
      "Usuario",
    ],
    exibe: true,
  },
  {
    id: 2,
    nome: "Entregas",
    link: "/entregas/geral",
    icon: "motorcycle_delivery_multiple_boxes_40px.png",
    iconType: "png",
    permissao: [
      "Administrador",
      "Gerente Moto",
      "Gerente Caixa",
      "Gerente",
      "Caixa",
      "Usuario",
    ],
    exibe: true,
  },
  {
    id: 2.1,
    nome: "EntregasVisaoGeral",
    link: "/entregas/geral",
    icon: "motorcycle_delivery_multiple_boxes_40px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente Moto", "Gerente Caixa", "Gerente"],
    exibe: false,
  },
  {
    id: 2.2,
    nome: "EntregasMotos",
    link: "/entregas/motos",
    icon: "motorcycle_delivery_multiple_boxes_40px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente Moto", "Gerente Caixa", "Gerente"],
    exibe: false,
  },
  {
    id: 2.3,
    nome: "EntregasPendentes",
    link: "/entregas/pendentes",
    icon: "motorcycle_delivery_multiple_boxes_40px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente Moto", "Gerente Caixa", "Gerente"],
    exibe: false,
  },
  {
    id: 2.4,
    nome: "Entregasrota",
    link: "/entregas/rota",
    icon: "motorcycle_delivery_multiple_boxes_40px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente Moto", "Gerente Caixa", "Gerente"],
    exibe: false,
  },
  {
    id: 2.5,
    nome: "EntregasConcluidas",
    link: "/entregas/concluidas",
    icon: "motorcycle_delivery_multiple_boxes_40px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente Moto", "Gerente Caixa", "Gerente"],
    exibe: false,
  },
  {
    id: 2.6,
    nome: "EntregasBuscar",
    link: "/entregas/buscar",
    icon: "motorcycle_delivery_multiple_boxes_40px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente Moto", "Gerente Caixa", "Gerente"],
    exibe: false,
  },

  {
    id: 3,
    nome: hasViewMesas && hasViewMesas == "comandas" ? "Comandas" : "Mesas",
    link: "/mesas/geral",
    icon:
      !hasViewMesas || hasViewMesas == "mesas"
        ? "icons8_table_top_view_48px.png"
        : "fa fa-barcode",
    iconType: hasViewMesas && hasViewMesas == "comandas" ? "fa" : "png",
    permissao: [
      "Administrador",
      "Gerente Moto",
      "Gerente Caixa",
      "Gerente",
      "Caixa",
      "Usuario",
      "Garçom",
      "Caixa Mesas",
    ],
    exibe: true,
  },
  {
    id: 3.1,
    nome: "MesasGeral",
    link: "/mesas/geral",
    icon: "icons8_table_top_view_48px.png",
    iconType: "png",
    permissao: [
      "Administrador",
      "Gerente Moto",
      "Gerente Caixa",
      "Gerente",
      "Caixa",
      "Usuario",
      "Garçom",
    ],
    exibe: false,
  },
  {
    id: 3.2,
    nome: "MesasCaixa",
    link: "/mesas/caixas",
    icon: "icons8_table_top_view_48px.png",
    iconType: "png",
    permissao: [
      "Administrador",
      "Gerente Moto",
      "Gerente Caixa",
      "Gerente",
      "Caixa",
      "Usuario",
      "Garçom",
    ],
    exibe: false,
  },
  {
    id: 3.3,
    nome: "MesasCardapio",
    link: "/mesas/cardapio",
    icon: "icons8_table_top_view_48px.png",
    iconType: "png",
    permissao: [
      "Administrador",
      "Gerente Moto",
      "Gerente Caixa",
      "Gerente",
      "Caixa",
      "Usuario",
      "Garçom",
    ],
    exibe: false,
  },
  {
    id: 3.4,
    nome: "MesasReservas",
    link: "/mesas/reservas",
    icon: "icons8_table_top_view_48px.png",
    iconType: "png",
    permissao: [
      "Administrador",
      "Gerente Moto",
      "Gerente Caixa",
      "Gerente",
      "Caixa",
      "Usuario",
      "Garçom",
    ],
    exibe: false,
  },
  {
    id: 5,
    nome: "Financeiro",
    link: "/financeiro",
    icon: "sales_performance_40px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente"],
    exibe: true,
  },
  {
    id: 5.1,
    nome: "FinanceiroSaidas",
    link: "/financeiro/saidas",
    icon: "sales_performance_40px.png",
    iconType: "png",
    permissao: ["Administrador"],
    exibe: false,
  },

  {
    id: 5.2,
    nome: "FinanceiroCP",
    link: "/financeiro/cp",
    icon: "sales_performance_40px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente"],
    exibe: false,
  },
  {
    id: 5.3,
    nome: "FinanceiroVales",
    link: "/financeiro/vales",
    icon: "sales_performance_40px.png",
    iconType: "png",
    permissao: ["Administrador"],
    exibe: false,
  },
  {
    id: 5.4,
    nome: "FinanceiroEstoque",
    link: "/financeiro/estoque",
    icon: "sales_performance_40px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente"],
    exibe: false,
  },
  {
    id: 5.5,
    nome: "FinanceiroFiscal",
    link: "/financeiro/fiscal",
    icon: "fa fa-dollar",
    iconType: "fa",
    permissao: ["Administrador"],
    exibe: false,
  },
  {
    id: 5.6,
    nome: "FinanceiroRelatorio",
    link: "/financeiro/movimentacao-financeira/",
    icon: "",
    iconType: "fa",
    permissao: ["Administrador", "Gerente"],
    exibe: false,
  },
  {
    id: 6.0,
    nome: "Disp. Estoque",
    link: "/disponibilidadeEstoque",
    icon: "icons8_trolley_40px.png",
    iconType: "png",
    permissao: ["Gerente Caixa"],
    exibe: true,
  },
  {
    id: 6,
    nome: "Estoque",
    link: "/estoque/movimentacao",
    icon: "icons8_trolley_40px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente", "Estoque"],
    exibe: true,
    children: [
      {
        id: 6.1,
        nome: "Movimentação",
        link: "/estoque/movimentacao",
        icon: "icons8_trolley_40px.png",
        iconType: "png",
        permissao: ["Administrador", "Gerente Caixa", "Gerente", "Estoque"],
        exibe: false,
      },
      {
        id: 6.2,
        nome: "Estoque",
        link: "/estoque/estoque",
        icon: "icons8_trolley_40px.png",
        iconType: "png",
        permissao: ["Administrador", "Gerente Caixa", "Gerente", "Estoque"],
        exibe: false,
      },
      {
        id: 6.3,
        nome: "Produtos",
        link: "/estoque/produtos",
        icon: "fas fa-pizza-slice",
        iconType: "fa",
        permissao: ["Administrador", "Gerente"],
        exibe: false,
      },
      {
        id: 6.4,
        nome: "Ingredientes",
        link: "/estoque/ingredientes",
        icon: "fas fa-bacon",
        iconType: "fa",
        permissao: ["Administrador", "Gerente"],
        exibe: false,
      },
      {
        id: 6.5,
        nome: "Disp. Estoque",
        link: "/estoque/disponibilidadeEstoque",
        icon: "icons8_trolley_40px.png",
        iconType: "png",
        permissao: ["Gerente Caixa"],
        exibe: false,
      },
      {
        id: 6.6,
        nome: "Inventário",
        link: "/estoque/inventario",
        icon: "fas fa-sliders-h",
        iconType: "fa",
        permissao: ["Administrador", "Gerente"],
        exibe: false,
      },
      {
        id: 6.7,
        nome: "Fornecedores",
        link: "/estoque/fornecedores",
        icon: "fas fa-truck",
        iconType: "fa",
        permissao: ["Administrador", "Gerente"],
        exibe: false,
      },
      {
        id: 6.8,
        nome: "Pedidos de Compra",
        link: "/estoque/pedidos",
        icon: "fas fa-file-invoice",
        iconType: "fa",
        permissao: ["Administrador", "Gerente"],
        exibe: false,
      },
      {
        id: 6.9,
        nome: "Solicitação de Compra",
        link: "/estoque/requisicao",
        icon: "fas fa-shopping-cart",
        iconType: "fa",
        permissao: ["Administrador", "Gerente"],
        exibe: false,
      },
    ],
  },
  {
    id: 6.998,
    nome: "Fiscal",
    link: "/fiscal/home",
    icon: "fa fa-dollar-sign",
    iconType: "fa",
    exibe: true,
    permissao: ["Administrador", "Gerente", "Contador"],
    children: [
      {
        id: 6.999,
        nome: "Fiscal",
        link: "/fiscal/home",
        icon: "fa fa-dollar-sign",
        iconType: "fa",
        exibe: false,
        permissao: ["Administrador", "Gerente", "Contador"],
      },
      {
        id: 6.9994,
        nome: "Notas",
        link: "/fiscal/notas",
        icon: "fa fa-dollar-sign",
        iconType: "fa",
        exibe: false,
        permissao: ["Administrador", "Gerente", "Contador"],
      },
      {
        id: 6.99942,
        nome: "FiscalConfigs",
        link: "/fiscal/configuracoes",
        icon: "fa fa-cogs",
        iconType: "fa",
        exibe: false,
        permissao: ["Administrador", "Gerente", "Contador"],
      },
      {
        id: 6.99943,
        nome: "FiscalBaixarXML",
        link: "/fiscal/baixar_xml",
        icon: "fa fa-download",
        iconType: "fa",
        exibe: false,
        permissao: ["Administrador", "Gerente", "Contador"],
      },
      {
        id: 6.99941,
        nome: "FiscalInutilizar",
        link: "/fiscal/inutilizacao",
        icon: "fa fa-ban",
        iconType: "fa",
        exibe: false,
        permissao: ["Administrador", "Gerente", "Contador"],
      },
    ],
  },
  {
    id: 7,
    nome: "Configs.",
    link: "#",
    icon: "automation_40px.png",
    iconType: "png",
    permissao: ["Administrador"],
    exibe: true,
    children: [
      {
        grupo: "Impressão",
        nome: "Impressoras",
        link: "/configuracoes/impressoras",
        icon: "fa fa-print",
        iconType: "fa",
        permissao: ["super", "Administrador"],
        exibe: true,
      },
      {
        grupo: "Impressão",
        nome: "Modelos",
        link: "/configuracoes/modelos-impressao",
        icon: "fa fa-receipt",
        iconType: "fa",
        permissao: ["super", "Administrador"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Empresas",
        link: "/configuracoes/empresas",
        icon: "fas fa-building",
        iconType: "fa",
        permissao: ["Administrador"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Clientes",
        link: "/configuracoes/clientes",
        icon: "fas fa-user-friends",
        iconType: "fa",
        permissao: ["Administrador"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Usuários",
        link: "/configuracoes/usuarios",
        icon: "fa fa-users",
        iconType: "fa",
        permissao: ["Administrador"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Fornecedores",
        link: "/configuracoes/fornecedores",
        icon: "fas fa-truck-loading",
        iconType: "fa",
        permissao: ["Administrador"],
        exibe: true,
      },

      {
        grupo: "Cadastros",
        nome: "Mesas",
        link: "/configuracoes/mesas",
        icon: "table_50px.png",
        iconType: "png",
        permissao: ["Administrador"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Grupo de Produtos",
        link: "/configuracoes/grupo_produtos",
        icon: "fas fa-boxes",
        iconType: "fa",
        permissao: ["Administrador", "Gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Produtos",
        link: "/configuracoes/produtos",
        icon: "fas fa-pizza-slice",
        iconType: "fa",
        permissao: ["Administrador", "Gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Opcionais de Produtos",
        link: "/configuracoes/produtos-opcionais",
        icon: "fas fa-tags",
        iconType: "fa",
        permissao: ["Administrador", "Gerente"],
        exibe: true,
      },
      {
        grupo: "Cadastros",
        nome: "Ingredientes",
        link: "/configuracoes/ingredientes",
        icon: "fas fa-bacon",
        iconType: "fa",
        permissao: ["Administrador"],
        exibe: true,
      },

      {
        grupo: "Cadastros",
        nome: "Gerador QRcode",
        link: "/configuracoes/qrcode",
        icon: "fas fa-qrcode",
        iconType: "fa",
        permissao: ["Administrador"],
        exibe: true,
      },

      {
        grupo: "Financeiro",
        nome: "Contas A Pagar",
        link: "/configuracoes/contas_pagar",
        icon: "fa fa-hand-holding-usd",
        iconType: "fa",
        permissao: ["Administrador"],
        exibe: true,
      },
      {
        grupo: "Financeiro",
        nome: "Carteiras/Contas",
        link: "/configuracoes/carteiras",
        icon: "fa fa-wallet",
        iconType: "fa",
        permissao: ["Administrador", "Gerente", "Gerente Caixa"],
        exibe: true,
      },
      {
        grupo: "Financeiro",
        nome: "Tipos de Histórico",
        link: "/configuracoes/tipo_historico",
        icon: "fa fa-tags",
        iconType: "fa",
        permissao: ["Administrador", "Gerente", "Gerente Caixa"],
        exibe: true,
      },
      {
        grupo: "Financeiro",
        nome: "Movimentação",
        link: "/financeiro/bancario",
        icon: "fa fa-university",
        iconType: "fa",
        permissao: ["Administrador", "Gerente", "Gerente Caixa"],
        exibe: true,
      },
      {
        grupo: "Financeiro",
        nome: "Bancário",
        link: "/financeiro/bancario/movimentacao",
        icon: "fa fa-university",
        iconType: "fa",
        permissao: ["Administrador", "Gerente", "Gerente Caixa"],
        exibe: true,
      },
      {
        grupo: "Financeiro",
        nome: "Bancário/Resumo",
        link: "/financeiro/bancario/resumo",
        icon: "fa fa-university",
        iconType: "fa",
        permissao: ["Administrador", "Gerente", "Gerente Caixa"],
        exibe: true,
      },
      {
        grupo: "Financeiro",
        nome: "Bancário/Extrato",
        link: "/financeiro/bancario/extrato",
        icon: "fa fa-university",
        iconType: "fa",
        permissao: ["Administrador"],
        exibe: true,
      },
      {
        grupo: "Financeiro",
        nome: "Centros de Custo",
        link: "/configuracoes/centros_de_custo",
        icon: "fas fa-dollar-sign",
        iconType: "fa",
        permissao: ["Administrador", "Gerente"],
        exibe: true,
      },
      {
        grupo: "Financeiro",
        nome: "Plano Contas",
        link: "/configuracoes/plano_contas",
        icon: "fas fa-funnel-dollar",
        iconType: "fa",
        permissao: ["Administrador", "Gerente"],
        exibe: true,
      },

      //     {
      //         grupo:'Cadastros',
      //         nome: "Usuários",
      //         link: "cadastro/usuarios",
      //         icon: "user_account_40px.png",
      //         iconType: 'png',
      //         permissao: ["super", "admin", "user"],
      //         exibe:true
      //     },
      //     {
      //         grupo:'Cadastros',
      //         nome: "Usuários",
      //         link: "cadastro/usuarios",
      //         icon: "user_account_40px.png",
      //         iconType: 'png',
      //         permissao: ["super", "admin", "user"],
      //         exibe:true
      //     },
      //     {
      //         grupo:'Cadastros',
      //         nome: "Usuários",
      //         link: "cadastro/usuarios",
      //         icon: "user_account_40px.png",
      //         iconType: 'png',
      //         permissao: ["super", "admin", "user"],
      //         exibe:true
      //     },
      //     {
      //         grupo:'Cadastros',
      //         nome: "Usuários",
      //         link: "cadastro/usuarios",
      //         icon: "user_account_40px.png",
      //         iconType: 'png',
      //         permissao: ["super", "admin", "user"],
      //         exibe:true
      //     },
      //     {
      //         grupo:'Cadastros',
      //         nome: "Usuários",
      //         link: "cadastro/usuarios",
      //         icon: "user_account_40px.png",
      //         iconType: 'png',
      //         permissao: ["super", "admin", "user"],
      //         exibe:true
      //     },
      //     {
      //         grupo:'Cadastros',
      //         nome: "Usuários",
      //         link: "cadastro/usuarios",
      //         icon: "user_account_40px.png",
      //         iconType: 'png',
      //         permissao: ["super", "admin", "user"],
      //         exibe:true
      //     },
    ],
  },
  {
    id: 7,
    nome: "Relatórios",
    link: "/relatorios/consumo-estoque/",
    icon: "chart_40px.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente"],
    exibe: true,
  },
  {
    id: 7.1,
    nome: "Relatórios",
    link: "/relatorios/pedidos/",
    icon: "",
    iconType: "fa",
    permissao: ["Administrador", "Gerente Caixa", "Gerente"],
    exibe: false,
  },
  {
    id: 7.1,
    nome: "Relatórios",
    link: "/relatorios/produtos-vendidos/",
    icon: "",
    iconType: "fa",
    permissao: ["Administrador", "Gerente Caixa", "Gerente"],
    exibe: false,
  },
  {
    id: 7.2,
    nome: "Relatórios",
    link: "/relatorios/acessos-usuario/",
    icon: "",
    iconType: "fa",
    permissao: ["Administrador", "Gerente"],
    exibe: false,
  },

  {
    id: 8,
    nome: "IFood",
    link: "/ifood",
    icon: "ifoodLogox40.png",
    iconType: "png",
    permissao: ["Administrador", "Gerente"],
    exibe: true,
  },
  //
  // {
  //   id: 99,
  //   nome: "Sair",
  //   link: "/logout",
  //   icon: "shutdown_40px.png",
  //   iconType: "png",
  //   permissao: ["*"],
  //   exibe: true,
  //   rtl: true,
  // },
];

export default menu;
