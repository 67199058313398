<template>
  <div class="cardapio-produtos">
    <!-- Loading state -->
    <div v-if="loading" class="d-flex justify-content-center py-5">
      <b-spinner variant="danger" label="Carregando..."></b-spinner>
    </div>

    <template v-else>
      <!-- Cabeçalho com navegação e busca -->
      <div class="cardapio-header">
        <div class="header-container">
          <!-- Título do grupo selecionado -->
          <h2 class="grupo-title" v-if="subGrupo">{{ subGrupo.Grupo }}</h2>

          <!-- Ações do cabeçalho -->
          <div class="header-actions">
            <!-- Botão de busca -->
            <button class="action-icon-button" @click="toggleBusca">
              <i class="fa" :class="[showBusca ? 'fa-times' : 'fa-search']"></i>
            </button>

            <!-- Botão de fechar (sempre visível) -->
            <button class="action-icon-button" @click="$emit('close')">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>

        <!-- Barra de busca (oculta por padrão) -->
        <div class="search-container" v-show="showBusca">
          <div class="search-input-wrapper">
            <i class="fa fa-search search-icon"></i>
            <input
              ref="searchInput"
              type="text"
              class="search-input"
              placeholder="Buscar no cardápio..."
              v-model="filtrar"
              @input="filtrarProdutos"
            />
            <button v-if="filtrar" class="clear-search" @click="limparBusca">
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- Categorias com scroll horizontal (estilo iFood) -->
      <div class="categorias-scroll" v-if="subgrupos && subgrupos.length > 1 && !filtrar">
        <div class="categorias-wrapper" ref="categoriasScroll">
          <div
            v-for="(subgrupo, index) in subgrupos"
            :key="'cat-' + index"
            class="categoria-item"
            :class="{ 'categoria-ativa': categoriaAtiva === subgrupo.Tipo }"
            @click="selecionarCategoria(subgrupo.Tipo)"
          >
            <span>{{ subgrupo.Tipo }}</span>
          </div>
        </div>
      </div>

      <!-- Quando há busca ou não há subgrupos, mostrar todos os produtos -->
      <div
        class="produtos-container"
        v-if="filtrar || !subgrupos || subgrupos.length <= 1 || !categoriaAtiva"
      >
        <div class="produtos-grid" v-if="produtosFiltrados.length > 0">
          <div
            v-for="produto in produtosFiltrados"
            :key="(produto.cod_produto || produto.id) + '-item'"
            class="mb-0 mb-md-3"
          >
            <CardapioItem
              :produto="produto"
              :empresa="empresaSelecionada"
              :mesa="mesa"
              @quantidade-alterada="handleQuantidadeAlterada"
            />
          </div>
        </div>
      </div>

      <!-- Exibição por categorias quando não há filtro e há categorias selecionadas -->
      <div class="produtos-container" v-else-if="categoriaAtiva">
        <div
          class="subgrupo-section"
          v-for="(subgrupo, index) in subgruposFiltrados"
          :key="'sg-' + index"
        >
          <div class="produtos-grid">
            <div
              v-for="produto in subgrupo.produtos"
              :key="(produto.cod_produto || produto.id) + '-item'"
              class="mb-3"
            >
              <CardapioItem
                :produto="produto"
                :empresa="empresaSelecionada"
                :mesa="mesa"
                @quantidade-alterada="handleQuantidadeAlterada"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Mensagem quando não há produtos -->
      <div
        class="empty-state"
        v-if="
          (!produtosFiltrados || produtosFiltrados.length === 0) &&
          (!subgruposFiltrados || subgruposFiltrados.length === 0)
        "
      >
        <i class="fa fa-search-minus empty-icon"></i>
        <p>
          {{
            filtrar
              ? "Nenhum produto encontrado com esse termo."
              : "Não há produtos disponíveis nesta categoria."
          }}
        </p>
        <button v-if="filtrar" class="btn btn-outline-danger mt-3" @click="limparBusca">
          Limpar busca
        </button>
        <button
          v-if="categoriaAtiva"
          class="btn btn-outline-secondary mt-2"
          @click="categoriaAtiva = null"
        >
          Ver todas as categorias
        </button>
      </div>

      <!-- Botões fixos de rodapé (sempre visíveis) -->
      <div class="fixed-actions">
        <!-- Mostrar resumo do carrinho quando tiver items -->
        <div class="cart-summary" v-if="totalQuantidade > 0">
          <div class="summary-content">
            <div class="summary-info">
              <span class="summary-count"
                >{{ totalQuantidade }}
                {{ totalQuantidade === 1 ? "item" : "itens" }}</span
              >
              <span class="summary-price">{{ formatarPreco(totalPreco) }}</span>
            </div>
            <button class="summary-button" @click="$router.push('/pedido')">
              Ver pedido <i class="fa fa-arrow-right ml-2"></i>
            </button>
          </div>
        </div>

        <!-- Botões de adicionar/fechar quando não houver items no carrinho -->
        <div class="fixed-buttons" v-else>
          <button class="btn-adicionar" @click="$emit('saveAndClose')">
            Adicionar ao pedido
          </button>
          <button class="btn-fechar" @click="$emit('close')">Fechar</button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import CardapioItem from "./CardapioItem.vue";

export default {
  components: {
    CardapioItem,
  },
  props: {
    add: Function,
    grupoSelecionado: Object,
    produtosShow: Array,
    mesa: Object,
    isMobile: Boolean,
    subgrupos: Array,
    opc_selecionada: Array,
    preco_tipo: Object,
    empresaSelecionada: Number,
  },
  data() {
    return {
      loading: false,
      filtrar: "",
      showBusca: false,
      produtosFiltrados: [],
      carrinho: {},
      subGrupo: this.grupoSelecionado,
      categoriaAtiva: null,
    };
  },
  computed: {
    totalQuantidade() {
      return Object.values(this.carrinho).reduce(
        (total, item) => total + item.quantidade,
        0
      );
    },
    totalPreco() {
      return Object.values(this.carrinho).reduce((total, item) => {
        return total + item.preco * item.quantidade;
      }, 0);
    },
    subgruposFiltrados() {
      if (!this.subgrupos || !this.categoriaAtiva) {
        return [];
      }

      return this.subgrupos.filter((subgrupo) => subgrupo.Tipo === this.categoriaAtiva);
    },
  },
  watch: {
    produtosShow: {
      immediate: true,
      handler() {
        this.inicializarProdutos();
      },
    },
    grupoSelecionado: {
      immediate: true,
      handler(novo) {
        this.subGrupo = novo;
      },
    },
    subgrupos: {
      immediate: true,
      handler(novos) {
        if (novos && novos.length > 0) {
          // Reset à categoria ativa quando os subgrupos mudarem
          this.categoriaAtiva = null;
        }
      },
    },
    showBusca(novoValor) {
      if (novoValor) {
        // Focar no input de busca quando ele for exibido
        this.$nextTick(() => {
          if (this.$refs.searchInput) {
            this.$refs.searchInput.focus();
          }
        });
      } else {
        // Limpar a busca quando o input for escondido
        this.limparBusca();
      }
    },
  },
  mounted() {
    this.inicializarProdutos();
    this.configurarScrollLateral();
  },
  methods: {
    toggleBusca() {
      this.showBusca = !this.showBusca;
    },

    inicializarProdutos() {
      this.loading = true;

      // Usar os produtos recebidos diretamente das props
      if (this.produtosShow && Array.isArray(this.produtosShow)) {
        this.produtosFiltrados = [...this.produtosShow];
      } else {
        this.produtosFiltrados = [];
      }

      this.loading = false;
    },

    configurarScrollLateral() {
      // Permitir navegação usando swipe em dispositivos de toque
      if (this.$refs.categoriasScroll) {
        let isDown = false;
        let startX;
        let scrollLeft;

        const scrollContainer = this.$refs.categoriasScroll;

        // Eventos de mouse
        scrollContainer.addEventListener("mousedown", (e) => {
          isDown = true;
          scrollContainer.classList.add("active");
          startX = e.pageX - scrollContainer.offsetLeft;
          scrollLeft = scrollContainer.scrollLeft;
        });

        scrollContainer.addEventListener("mouseleave", () => {
          isDown = false;
          scrollContainer.classList.remove("active");
        });

        scrollContainer.addEventListener("mouseup", () => {
          isDown = false;
          scrollContainer.classList.remove("active");
        });

        scrollContainer.addEventListener("mousemove", (e) => {
          if (!isDown) return;
          e.preventDefault();
          const x = e.pageX - scrollContainer.offsetLeft;
          const walk = (x - startX) * 2; // Multiplicador de velocidade do scroll
          scrollContainer.scrollLeft = scrollLeft - walk;
        });
      }
    },

    selecionarCategoria(tipo) {
      if (this.categoriaAtiva === tipo) {
        // Se clicar na mesma categoria, desativar a filtragem
        this.categoriaAtiva = null;
      } else {
        this.categoriaAtiva = tipo;

        // Scroll automático para a categoria selecionada
        this.$nextTick(() => {
          const activeEl = this.$el.querySelector(".categoria-ativa");
          if (activeEl && this.$refs.categoriasScroll) {
            const scrollContainer = this.$refs.categoriasScroll;
            const containerWidth = scrollContainer.offsetWidth;
            const elLeft = activeEl.offsetLeft;
            const elWidth = activeEl.offsetWidth;

            // Centralizar o elemento na visão
            scrollContainer.scrollLeft = elLeft - containerWidth / 2 + elWidth / 2;
          }
        });
      }
    },

    filtrarProdutos() {
      if (!this.produtosShow || !Array.isArray(this.produtosShow)) {
        return;
      }

      // Resetar categoria ativa quando estiver filtrando
      if (this.filtrar) {
        this.categoriaAtiva = null;
      }

      if (!this.filtrar) {
        this.produtosFiltrados = [...this.produtosShow];
        return;
      }

      const termoBusca = this.filtrar.toLowerCase();
      this.produtosFiltrados = this.produtosShow.filter((produto) => {
        // Verificar todas as propriedades possíveis
        const nome = (
          produto.Produto ||
          produto.nome ||
          produto.name ||
          ""
        ).toLowerCase();
        const descricao = (
          produto.Descricao ||
          produto.descricao ||
          produto.description ||
          ""
        ).toLowerCase();

        return nome.includes(termoBusca) || descricao.includes(termoBusca);
      });
    },

    limparBusca() {
      this.filtrar = "";
      this.filtrarProdutos();
    },

    handleQuantidadeAlterada(produto, quantidade) {
      produto.qtd = quantidade;
      
      // Se temos a função add das props, usamos ela para compatibilidade
      if (this.add) {
        this.add(produto);
      }

      // Atualizar o carrinho local
      if (quantidade > 0) {
        const produtoId = produto.cod_produto || produto.id;
        this.carrinho[produtoId] = {
          quantidade: quantidade,
          preco: this.calcularPreco(produto),
          produto: produto,
        };
      } else {
        console.log("quantidade", quantidade);

        const produtoId = produto.cod_produto || produto.id;
        if (this.carrinho[produtoId]) {
          delete this.carrinho[produtoId];
          console.log("carrinho", this.carrinho);
        }
      }
    },

    calcularPreco(produto) {
      // Preço para produtos com preços variáveis (como pizzas)
      if (this.preco_tipo && produto.precos) {
        const preco = produto.precos.find((p) => p.cod_tipo === this.preco_tipo.cod_tipo);
        if (preco) {
          return parseFloat(preco.inteira || preco.preco_venda || 0);
        }
      }

      // Preço padrão
      return parseFloat(
        produto.preco_venda || produto.Preco || produto.preco || produto.price || 0
      );
    },

    formatarPreco(valor) {
      return `R$ ${valor.toFixed(2).replace(".", ",")}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.cardapio-produtos {
  position: relative;
  padding-bottom: 80px; // Espaço para o resumo do carrinho e botões fixos
}

/* ===== CABEÇALHO ===== */
.cardapio-header {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fff;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
  padding: 12px 16px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grupo-title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #333;
  text-transform: capitalize;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-icon-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f8f8f8;
  border: 1px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
    color: #333;
  }

  &:active {
    transform: scale(0.95);
  }
}

/* ===== BARRA DE BUSCA ===== */
.search-container {
  margin-top: 10px;
  overflow: hidden;
  transition: height 0.3s ease;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.search-icon {
  position: absolute;
  left: 15px;
  color: #999;
}

.search-input {
  width: 100%;
  padding: 12px 40px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  background-color: #f8f8f8;
  font-size: 15px;

  &:focus {
    outline: none;
    border-color: #d32f2f;
    background-color: #fff;
    box-shadow: 0 0 0 2px rgba(211, 47, 47, 0.15);
  }

  &::placeholder {
    color: #999;
  }
}

.clear-search {
  position: absolute;
  right: 15px;
  background: none;
  border: none;
  color: #999;
  cursor: pointer;

  &:hover {
    color: #666;
  }
}

/* ===== CATEGORIAS ===== */
.categorias-scroll {
  position: sticky;
  top: 62px; // Posicionar abaixo da barra de busca que tem position: sticky
  z-index: 9;
  background-color: #fff;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border-bottom: 1px solid #eee;
  padding: 6px 0;
}

.categorias-wrapper {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  padding: 0 16px;

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }

  &.active {
    cursor: grabbing;
  }
}

.categoria-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  padding: 8px 16px;
  background-color: #f8f8f8;
  border-radius: 20px;
  @media screen and (max-width: 575px) {
    padding: 4px 8px;
    margin-right: 4px;
    font-size: 12px;
  }
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s;
  font-weight: 500;
  color: #666;
  border: 1px solid #eee;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: #f0f0f0;
  }

  &.categoria-ativa {
    background-color: #d32f2f;
    color: white;
    border-color: #d32f2f;

    &:hover {
      background-color: #b71c1c;
    }
  }
}

/* ===== CONTAINER DE PRODUTOS ===== */
.produtos-container {
  padding-bottom: 20px;
}

.subgrupo-section {
  margin-bottom: 24px;
}

.subgrupo-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0 16px 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #eee;
}

.produtos-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px;
  padding: 10px 16px;
  margin-bottom: 20px;

  @media screen and (max-width: 575px) {
    padding: 0px;
    gap: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  text-align: center;
  color: #666;

  .empty-icon {
    font-size: 3rem;
    margin-bottom: 16px;
    color: #ccc;
  }

  p {
    font-size: 16px;
    max-width: 300px;
    margin-bottom: 0;
  }
}

/* ===== BOTÕES FIXOS E CARRINHO ===== */
.fixed-actions {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
}

.fixed-buttons {
  display: flex;
  padding: 10px 16px;
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);

  .btn-adicionar {
    flex: 3;
    background-color: #d32f2f;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 20px;
    font-weight: 500;
    transition: background-color 0.2s;
    margin-right: 8px;

    &:hover {
      background-color: #b71c1c;
    }

    &:active {
      transform: scale(0.98);
    }
  }

  .btn-fechar {
    flex: 1;
    background-color: #f5f5f5;
    color: #666;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 12px 10px;
    font-weight: 500;
    transition: all 0.2s;

    &:hover {
      background-color: #eee;
      color: #333;
    }

    &:active {
      transform: scale(0.98);
    }
  }
}

.cart-summary {
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 16px;

  .summary-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }

  .summary-info {
    display: flex;
    flex-direction: column;
  }

  .summary-count {
    font-size: 13px;
    color: #666;
  }

  .summary-price {
    font-weight: 600;
    font-size: 16px;
    color: #333;
  }

  .summary-button {
    background-color: #d32f2f; // Vermelho
    color: white;
    border: none;
    border-radius: 8px;
    padding: 12px 20px;
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;
    transition: background-color 0.2s;

    &:hover {
      background-color: #b71c1c; // Vermelho mais escuro no hover
    }

    &:active {
      transform: scale(0.98);
    }
  }
}

// Ajustar para dispositivos com notch ou barra de navegação no bottom
@media (display-mode: standalone) {
  .fixed-actions {
    padding-bottom: env(safe-area-inset-bottom, 0px);
  }
}
</style>
