<template>
  <div class="contentMesas">
    <b-modal
      id="modal-alter-data"
      hide-footer
      hide-header
      content-class="p-0"
      modal-class="p-0"
    >
      <div class="row m-0">
        <div class="col-12 col-sm-6 pr-0 pl-0">
          <empresa-combo
            :selecionar="changeEmpresa"
            :noStyle="false"
            :comTodos="false"
            :selected="empresaSel.cod_empresa"
          />
        </div>
        <div class="col-12 col-sm-6 bg-menu pb-2">
          <div class="text-center p-0 pt-2 pb-2">
            <i class="fa fa-user" /> Operador {{ user.nome }}
          </div>
          <v-date-picker
            v-model="data"
            mode="date"
            :rows="1"
            spellcheck="false"
            is-expanded
            is-required
          >
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="form-control text-center"
                :disabled="loading"
                :value="inputValue"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
      </div>
      <hr />
      <b-button
        variant="danger"
        block
        class="mt-2"
        @click="$router.push('/logout')"
        >Sair do Sistema / Trocar Usuário</b-button
      >
      <b-button variant="info" block class="mt-2" @click="forceUPD"
        >Forçar Atualização</b-button
      >
      <b-button
        variant="secondary"
        block
        class="mt-2"
        @click="$bvModal.hide('modal-alter-data')"
        >Fechar</b-button
      >
    </b-modal>
    <header class="d-none d-sm-block bg-menu">
      <div class="row m-0">
        <div class="col-12 col-sm-2 pr-0 pl-0">
          <b-btn
            block
            variant="info"
            @click="openQrCode(1)"
            style="height: 40px"
            class="my-1 px-2"
          >
            <i class="fas fa-qrcode" />
            Comanda
          </b-btn>
          <b-btn
            block
            variant="info"
            @click="openQrCode(2)"
            style="height: 40px"
            class="my-1 px-2"
          >
            <i class="fas fa-qrcode" />
             Pedido
          </b-btn>
        </div>
        <div class="col-12 col-sm-4 pr-0 pl-0">
          <empresa-combo
            :selecionar="changeEmpresa"
            :noStyle="false"
            :comTodos="false"
            :selected="empresaSel.cod_empresa"
          />
        </div>
        <div class="col-12 col-sm-6 pl-0 d-flex">
          <div class="flex-1 w-100">
            <div class="text-center p-2 pb-3">
              <i class="fa fa-user" /> Operador {{ user.nome }}
            </div>
            <v-date-picker
              v-model="data"
              mode="date"
              :rows="1"
              spellcheck="false"
              is-expanded
              is-required
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="form-control text-center"
                  :disabled="loading"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </div>

          <div class="pl-4 d-flex flex-column py-3 text-center w-50">
            <b-btn
              :variant="view == 'mesas' ? 'success' : 'secondary'"
              block
              @click="view = 'mesas'"
            >
              <img width="25" src="@/assets/img/icones/table.png" />

              MESAS
            </b-btn>
            <b-btn
              :variant="view == 'comandas' ? 'success' : 'secondary'"
              block
              @click="view = 'comandas'"
            >
              <i class="fa fa-barcode" />
              COMANDAS
            </b-btn>
          </div>
        </div>
      </div>
    </header>

    <router-view
      :mesas="mesas"
      :empresa="empresaSel"
      :data="dataFormatada"
      @updateMesa="updateMesa"
      :loading="loading"
      :view="view"
      @openQrCode="openQrCode"
      ref="mesas"
      @updateMesas="
        () => {
          joinRoom();
          //solicitarLista();
        }
      "
    />

    <div class="footer">
      <div class="menu">
        <a
          href="#"
          @click="$bvModal.show('modal-alter-data')"
          class="d-block d-sm-none menuData"
        >
          <i class="far fa-calendar" /> <br />
          {{ data | moment("DD/MM/YYYY") }} <br />
          <small>{{ empresaSel.nome }}</small>
        </a>
        <router-link to="/mesas/geral" active-class="selected">
          <img
            src="@/assets/img/icones/menu/icons8_table_top_view_48px.png"
            height="25px;"
            class="mb-1"
          />
          <span v-if="!$store.state.isMobile" class="ml-1"> Mesas </span>
        </router-link>
        <router-link
          to="/mesas/caixas"
          active-class="selected"
          v-if="podeRetirarPorc"
        >
          <img
            src="@/assets/img/icones/menu/cash_register_40px.png"
            height="25px;"
          />
          <span v-if="!$store.state.isMobile" class="ml-1"> Caixa </span>
        </router-link>
        <!-- <router-link to="/mesas/cardapio" active-class="selected">
          <img
            src="@/assets/img/icones/menu/icons8_restaurant_menu_48px.png"
            height="25px;"
          />
          <br />
          Cardápio
        </router-link> -->
        <router-link to="/mesas/reservas" active-class="selected">
          <span style="font-size: 19px">
            <b-icon-calendar-check />
          </span>
          <span v-if="!$store.state.isMobile" class="ml-1"> Reservas </span>
        </router-link>
        <a href="#" @click.prevent="openConfigs()">
          <span style="font-size: 20px; text-align: center">
            <i class="fa fa-cog" />
          </span>
        </a>
      </div>
    </div>
    <b-modal
      id="modal-configs-mesa"
      size="lg"
      class="modal-full"
      modal-class="modal-full-width"
      hide-footer
      hide-title
      @hidden="refresh"
    >
      <mesas-salao-config
        :mesas="mesas"
        :empresaSel="empresaSel"
        @refresh="refresh"
      />
    </b-modal>
    <b-modal
      id="modal-load-qrcode"
      title="Abrir Comanda por QrCode"
      hide-footer
    >
      <qrcode-stream
        @detect="onDetectQrCode"
        @error="handleQrcodeError"
        :track="paintOutline"
        v-if="!comandaReadedByQRCode"
      ></qrcode-stream>

      <div v-if="comandaReadedByQRCode">
        <h1 class="text-center">Comanda {{ comandaReadedByQRCode }}</h1>
        <hr />

        <h4 class="text-center">
          <b-spinner></b-spinner>
          Buscando comanda...
        </h4>
      </div>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import EmpresaCombo from "../../components/empresa/EmpresaCombo.vue";
import MesasSalaoConfig from "./MesasSalaoConfig.vue";
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  components: {
    EmpresaCombo,
    MesasSalaoConfig,
    QrcodeStream,
  },
  sockets: {
    connect() {
      console.log("conectado");
      setTimeout(() => {
        this.joinRoom();
      }, 1000);
      // this.solicitarLista();
    },
    solicitarLista() {
      // console.log("SOLICITADO", {
      //   cod_empresa: this.empresaSel.cod_empresa,
      //   data: moment(this.data).format("YYYY-MM-DD"),
      // });
      // this.$socket.emit("mesas:listMesas", {
      //   cod_empresa: this.empresaSel.cod_empresa,
      //   data: moment(this.data).format("YYYY-MM-DD"),
      // });
      // lista;
    },
    listMesas(lista) {
      // console.log("lista recebeida");
      //this.contaChamadas++;
      //console.log("aaaaaaaaaaaaaaaaaaaa", this.contaChamadas, lista);
      this.verificaLayout();

      this.mesas = lista;
      this.loading = false;
      // .sort((a, b) => {
      //   return a.dt_conta && b ? -1 : 1;
      // })
      // .sort((a, b) => {
      //   return a.dt_conta && b.dt_conta && a.cod_mesa < b.cod_mesa ? -1 : 1;
      // });
    },
  },
  props: {},
  mounted() {
    setTimeout(() => {
      //this.joinRoom();
      setTimeout(() => {
        //this.solicitarLista();
        this.joinRoom();
      }, 200);
    }, 300);
    this.view = window.localStorage.getItem("viewMesas") || "mesas";
  },
  unmounted() {
    this.leaveRoom();
  },
  data() {
    return {
      view: "mesas",
      layoutLoaded: false,
      contaChamadas: 0,
      loading: false,
      data: new Date(),
      empresaSel: this.$store.state.empresa_ativa,
      user: this.$store.state.auth.user,
      mesas: [],
      typeOpenQrCode: 1,
      comandaReadedByQRCode: null,
      dataFormatada: moment().format("YYYY-MM-DD"),
      layout: null,
      podeRetirarPorc:
        this.$store.state.auth.user.tipo.indexOf("Admin") >= 0 ||
        this.$store.state.auth.user.tipo.indexOf("Gerente") >= 0 ||
        this.$store.state.auth.user.tipo.indexOf("Caixa Mesas") >= 0 ||
        this.$store.state.auth.user.tipo.indexOf("Caixa") >= 0,
    };
  },
  watch: {
    empresaSel: function (n, old) {
      console.log("changeEmpresa", n, old);
      this.loading = true;

      this.joinRoom({ cod_empresa: old, data: this.data });
      this.verificaLayout();
    },
    data: function (n, old) {
      console.log("changeData", n, old);
      this.loading = true;

      this.dataFormatada = moment(this.data).format("YYYY-MM-DD");
      this.joinRoom({ cod_empresa: this.empresaSel.cod_empresa, data: old });
      //this.solicitarLista();
    },
    view: function () {
      console.log("view", this.view);
      // window.localStorage.
      let r = window.localStorage.setItem("viewMesas", this.view);
      console.log("view", r);
    },
  },
  computed: {},
  methods: {
    paintOutline(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const [firstPoint, ...otherPoints] = detectedCode.cornerPoints;

        ctx.strokeStyle = "red";

        ctx.beginPath();
        ctx.moveTo(firstPoint.x, firstPoint.y);
        for (const { x, y } of otherPoints) {
          ctx.lineTo(x, y);
        }
        ctx.lineTo(firstPoint.x, firstPoint.y);
        ctx.closePath();
        ctx.stroke();
      }
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#007bff";
        ctx.strokeRect(x, y, width, height);
      }
    },
    handleQrcodeError(err) {
      console.log("err", err);
    },
    async onDetectQrCode(val) {
      let contant = await val;

      console.log("val", contant);
      if (contant.content && contant.content.indexOf("open/comanda/") >= 0) {
        this.comandaReadedByQRCode = contant.content.split("open/comanda/")[1];
        let finded = false;
        for (let m of this.mesas.filter((x) => x.id)) {
          let comanda = m.comandas.find((x) =>
            x.nome.includes(`Comanda nº ${this.comandaReadedByQRCode} `)
          );
          if (comanda) {
            this.$refs.mesas.selectComanda(comanda, this.typeOpenQrCode);
            this.$bvModal.hide("modal-load-qrcode");
            finded = true;
          }
        }
        if(!finded){
          this.$refs.mesas.openNewComanda(this.comandaReadedByQRCode, this.typeOpenQrCode);
          

        }
      }
      // this.$bvModal.hide("modal-load-qrcode");

      // let qr = JSON.stringify(detectedCodes.map((code) => code.rawValue));
      // console.log("val", JSON.stringify(val.map((code) => code.rawValue)));
      this.$bvModal.hide("modal-load-qrcode");

    },
    openQrCode(typeOpenQrCode) {
      this.typeOpenQrCode = typeOpenQrCode;
      this.comandaReadedByQRCode = null;
      this.$bvModal.show("modal-load-qrcode");
    },
    forceUPD() {
      window.location.reload();
    },
    async refresh() {
      window.location.reload();
    },
    verificaLayout() {
      if (!this.layoutLoaded) {
        this.$store.dispatch("auth/atualizaEmpresas");
        if (
          this.empresaSel &&
          this.empresaSel.configs &&
          this.empresaSel.configs.mesasLayout &&
          this.empresaSel.configs.mesasLayout.length > 0
        ) {
          let has = this.empresaSel.configs.mesasLayout.find(
            (x) => x.ativo === true
          );
          if (has) {
            this.layout = has;
          }
        }
        this.layoutLoaded = true;
      }
    },
    openConfigs() {
      // console.log("abrindo");
      this.$bvModal.show("modal-configs-mesa");
    },
    leaveRoom() {
      this.$socket.emit("mesas:leaveRoom");
    },
    joinRoom(old = null) {
      old;
      // console.log("entrando na sala ", {
      //   cod_empresa: this.empresaSel.cod_empresa,
      //   leave: old,
      //   data: moment(this.data).format("YYYY-MM-DD"),
      // });
      this.$socket.emit("mesas:joinRoom", {
        cod_empresa: this.empresaSel.cod_empresa,
        leave: old,
        data: moment(this.data).format("YYYY-MM-DD"),
      });
      //this.solicitarLista();
      this.verificaLayout();
      this.solicitarLista();
    },
    changeEmpresa(emp) {
      // console.log("changeEmpresa2", emp);

      this.empresaSel = emp;
      this.solicitarLista();
      this.verificaLayout();

      // console.log(emp);
    },
    updateMesa(mesa) {
      if (mesa) {
        // console.log("MESA INFO===>", mesa, this.mesas);
        let ms = this.mesas.find((x) => x.id === mesa.id);
        if (ms) {
          ms = mesa;
          this.$forceUpdate();
          // console.log("updatesddd")
        }
      }
    },
    solicitarLista() {
      // console.log("SOLICITADO", {
      //   cod_empresa: this.empresaSel.cod_empresa,
      //   data: moment(this.data).format("YYYY-MM-DD")
      // });
      console.log("solicitando lista");
      this.loading = true;
      this.$socket.emit("mesas:listMesas", {
        cod_empresa: this.empresaSel.cod_empresa,
        data: moment(this.data).format("YYYY-MM-DD"),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mesas.scss";
</style>
