<template>
  <div class="cardapio-grupos">
    <div v-if="$store.state.loadingCardapio" class="loading-container">
      <b-spinner variant="danger" />
      <span>Carregando cardápio...</span>
    </div>
    
    <!-- Layout com scroll horizontal para todos os dispositivos -->
    <div class="grupos-scroll-container">
      <!-- Botões de navegação visíveis apenas em desktop -->
      <!-- <button class="scroll-button left" @click="back()" v-if="!isMobile">
        <i class="fa fa-chevron-left"></i>
      </button> -->
      
      <div class="grupos-wrapper" ref="gruposScroll">
        <div class="grupos-inner-container">
          <div 
            v-for="grupo in cardapio" 
            :key="grupo.cod_grupo"
            class="grupo-item"
            :class="{ 
              'grupo-ativo': grupoAtivo === grupo.cod_grupo,
              'mobile': isMobile || mesa
            }"
            @click="selecionarGrupo(grupo)"
          >
            <div class="grupo-icone">
              <img 
                :src="obterIconeGrupo(grupo)" 
                :alt="grupo.Grupo"
              />
            </div>
            <span class="grupo-nome">{{ grupo.Grupo }}</span>
          </div>
        </div>
      </div>
      
      <!-- Botões de navegação visíveis apenas em desktop -->
      <!-- <button class="scroll-button right" @click="next()" v-if="!isMobile">
        <i class="fa fa-chevron-right"></i>
      </button> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: Function,
    mesa: Object,
    empresaSelecionada: Number,
  },
  data() {
    return {
      cardapio: this.$store.state.cardapio,
      grupoAtivo: null,
      touchStartX: 0,
      touchStartY: 0,
      isTouching: false
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  watch: {
    "$store.state.cardapio": {
      deep: true,
      handler() {
        this.loadCards();
        this.$nextTick(() => {
          this.recalcularTamanhos();
        });
      },
    },
    empresaSelecionada: {
      deep: true,
      handler() {
        this.loadCards();
        this.$nextTick(() => {
          this.recalcularTamanhos();
        });
      },
    },
  },
  mounted() {
    this.loadCards();
    this.$nextTick(() => {
      this.configurarEventosTouch();
      this.recalcularTamanhos();
      
      // Adicionar evento de redimensionamento para recalcular tamanhos quando a janela for redimensionada
      window.addEventListener('resize', this.recalcularTamanhos);
    });
  },
  methods: {
    loadCards() {
      this.cardapio = this.$store.state.cardapio;
      
      // Processar imagens
      for (let it in this.cardapio) {
        if (it.imagens && typeof it.imagens == "string") {
          it.imagens = JSON.parse(it.imagens);
        }
      }
      
      // Filtrar por empresa
      let fndEmp = [
        this.$store.state.empresas,
        ...this.$store.state.empresas.empresas,
      ].find((x) => x.cod_empresa === this.empresaSelecionada);
      
      if (fndEmp && fndEmp.cardapio) {
        this.cardapio = this.cardapio.filter(
          (x) =>
            
            fndEmp.cardapio.some((p) => p.Grupo === x.Grupo && p.ativo === true)
        );
      }
    },
    
    recalcularTamanhos() {
      // Certifique-se de que o contêiner interno tenha largura suficiente
      if (this.$refs.gruposScroll) {
        const container = this.$refs.gruposScroll;
        const innerContainer = container.querySelector('.grupos-inner-container');
        
        if (innerContainer) {
          const totalItems = this.cardapio.length;
          const itemWidth = this.isMobile ? 90 : 110; // Largura aproximada de cada item incluindo margens
          const minContainerWidth = Math.max(totalItems * itemWidth, container.offsetWidth + 20);
          
          innerContainer.style.minWidth = `${minContainerWidth}px`;
          innerContainer.style.width = '100%';
          
          // Se o conteúdo for menor que o contêiner, centralize os itens
          if (totalItems * itemWidth < container.offsetWidth) {
            innerContainer.style.justifyContent = 'center';
          } else {
            innerContainer.style.justifyContent = 'flex-start';
          }
        }
      }
    },
    
    configurarEventosTouch() {
      const scrollContainer = this.$refs.gruposScroll;
      if (!scrollContainer) return;
      
      // Remover event listeners antigos antes de adicionar novos
      scrollContainer.removeEventListener('touchstart', this.handleTouchStart);
      scrollContainer.removeEventListener('touchmove', this.handleTouchMove);
      scrollContainer.removeEventListener('touchend', this.handleTouchEnd);
      scrollContainer.removeEventListener('wheel', this.handleWheel);
      
      // Adicionar event listeners para scroll touch e mouse
      scrollContainer.addEventListener('touchstart', this.handleTouchStart, { passive: true });
      scrollContainer.addEventListener('touchmove', this.handleTouchMove, { passive: false });
      scrollContainer.addEventListener('touchend', this.handleTouchEnd, { passive: true });
      scrollContainer.addEventListener('wheel', this.handleWheel, { passive: false });
      
      // Adicionar event listeners de mouse para arrastar
      scrollContainer.addEventListener('mousedown', this.handleMouseDown);
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mouseup', this.handleMouseUp);
    },
    
    handleTouchStart(e) {
      this.isTouching = true;
      this.touchStartX = e.touches[0].clientX;
      this.touchStartY = e.touches[0].clientY;
      this.lastTouchX = this.touchStartX;
      this.startScrollLeft = this.$refs.gruposScroll.scrollLeft;
    },
    
    handleTouchMove(e) {
      if (!this.isTouching) return;
      
      const touchX = e.touches[0].clientX;
      const touchY = e.touches[0].clientY;
      
      // Calcular diferenças de movimento
      const diffX = this.lastTouchX - touchX;
      const diffY = Math.abs(this.touchStartY - touchY);
      
      // Se o movimento horizontal for maior que um limite ou já estamos scrollando
      if (Math.abs(diffX) > 5 || (Math.abs(diffX) > diffY && Math.abs(diffX) > 1)) {
        // Prevenir scroll da página
        e.preventDefault();
        
        // Atualizar a posição de scroll
        if (this.$refs.gruposScroll) {
          this.$refs.gruposScroll.scrollLeft += diffX;
        }
        
        this.lastTouchX = touchX;
      }
    },
    
    handleTouchEnd() {
      this.isTouching = false;
    },
    
    handleMouseDown(e) {
      this.isMouseDown = true;
      this.startX = e.pageX - this.$refs.gruposScroll.offsetLeft;
      this.startScrollLeft = this.$refs.gruposScroll.scrollLeft;
      this.$refs.gruposScroll.style.cursor = 'grabbing';
    },
    
    handleMouseMove(e) {
      if (!this.isMouseDown) return;
      e.preventDefault();
      
      const x = e.pageX - this.$refs.gruposScroll.offsetLeft;
      const walk = (x - this.startX) * 2; // Velocidade do scroll
      this.$refs.gruposScroll.scrollLeft = this.startScrollLeft - walk;
    },
    
    handleMouseUp() {
      this.isMouseDown = false;
      if (this.$refs.gruposScroll) {
        this.$refs.gruposScroll.style.cursor = 'grab';
      }
    },
    
    handleWheel(e) {
      e.preventDefault();
      
      if (this.$refs.gruposScroll) {
        // Use deltaY para scroll horizontal
        this.$refs.gruposScroll.scrollLeft += e.deltaY;
      }
    },
    
    selecionarGrupo(grupo) {
      this.grupoAtivo = grupo.cod_grupo;
      if (this.open) {
        this.open(grupo);
      }
      
      // Centralizar o item selecionado após a seleção
      this.$nextTick(() => {
        const activeEl = this.$el.querySelector('.grupo-ativo');
        if (activeEl && this.$refs.gruposScroll) {
          const scrollContainer = this.$refs.gruposScroll;
          const containerWidth = scrollContainer.offsetWidth;
          const elLeft = activeEl.offsetLeft;
          const elWidth = activeEl.offsetWidth;
          
          // Centralizar o elemento na visão (considerando o deslocamento dentro do container interno)
          scrollContainer.scrollLeft = elLeft - (containerWidth / 2) + (elWidth / 2);
        }
      });
    },
    
    obterIconeGrupo(grupo) {
      // Se o grupo tem imagem própria
      if (grupo.imagens && grupo.imagens.length > 0) {
        return grupo.imagens[0].fileURL;
      }
      
      // Identificar e retornar o ícone adequado baseado no nome do grupo
      const nomeGrupo = grupo.Grupo.toLowerCase();
      
      if (nomeGrupo.includes('bebi')) {
        return require("@/assets/img/icons8_soda_can_96px.png");
      } else if (nomeGrupo.includes('pizza')) {
        return require("@/assets/img/icons8_pizza_96px.png");
      } else if (nomeGrupo.includes('porç')) {
        return require("@/assets/img/icons8_nachos_96px.png");
      } else if (nomeGrupo.includes('sushi - combo')) {
        return require("@/assets/img/icons8_sashimi_96px.png");
      } else if (nomeGrupo.includes('sushi - monte')) {
        return require("@/assets/img/icons8_kawaii_sushi_96px.png");
      } else if (nomeGrupo.includes('divers')) {
        return require("@/assets/img/icons8_melting_ice_cream_96px.png");
      } else if (nomeGrupo.includes('func')) {
        return require("@/assets/img/icons8_school_cafeteria_96px.png");
      } else if (nomeGrupo.includes('sorv')) {
        return require("@/assets/img/icons8_melting_ice_cream_96px.png");
      } else if (nomeGrupo.includes('sobre')) {
        return require("@/assets/img/icons8_strawberry_cheesecake_96px_1.png");
      } else if (nomeGrupo === 'combos') {
        return require("@/assets/img/ingredients_48px.png");
      } else if (nomeGrupo.includes('rod')) {
        return require("@/assets/img/icons8_process_48px_12.png");
      } else if (nomeGrupo.includes('esfih')) {
        return require("@/assets/img/icons8_cookies_96px.png");
      } else if (nomeGrupo.includes('burg')) {
        return require("@/assets/img/icons8_hamburger_96px.png");
      }
      
      // Ícone padrão
      return require("@/assets/img/icons8_nachos_96px.png");
    },
    
    back() {
      if (this.$refs.gruposScroll) {
        this.$refs.gruposScroll.scrollLeft -= 200;
      }
    },
    
    next() {
      if (this.$refs.gruposScroll) {
        this.$refs.gruposScroll.scrollLeft += 200;
      }
    },
  },
  beforeDestroy() {
    // Limpar todos os event listeners quando o componente for destruído
    if (this.$refs.gruposScroll) {
      this.$refs.gruposScroll.removeEventListener('touchstart', this.handleTouchStart);
      this.$refs.gruposScroll.removeEventListener('touchmove', this.handleTouchMove);
      this.$refs.gruposScroll.removeEventListener('touchend', this.handleTouchEnd);
      this.$refs.gruposScroll.removeEventListener('wheel', this.handleWheel);
      this.$refs.gruposScroll.removeEventListener('mousedown', this.handleMouseDown);
    }
    
    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('resize', this.recalcularTamanhos);
  },
};
</script>

<style lang="scss" scoped>
.cardapio-grupos {
  width: 100%;
  margin-bottom: 16px;
  overflow: hidden; /* Evitar overflow no contêiner pai */
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 20px;
  color: #666;
  
  .b-spinner {
    color: #d32f2f;
  }
}

/* Layout unificado para todos os dispositivos */
.grupos-scroll-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 8px;
}

.scroll-button {
  position: absolute;
  z-index: 5;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #e0e0e0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  
  &:hover {
    background-color: #f8f8f8;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
  }
  
  &.left {
    left: 8px;
  }
  
  &.right {
    right: 8px;
  }
  
  i {
    color: #666;
    font-size: 14px;
  }
}

.grupos-wrapper {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch; /* Para rolagem suave em iOS */
  padding: 0;
  cursor: grab;
  position: relative;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE e Edge */
  scroll-behavior: smooth;
  
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari e Opera */
  }
  
  &:active {
    cursor: grabbing;
  }
}

.grupos-inner-container {
  display: flex;
  align-items: center;
  padding: 0px 0;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 575px) {
    padding: 0px;
    width: 100%;
 
  }
  min-width: 100%;
  width: max-content; /* Garantir que o contêiner seja largo o suficiente */
}

.grupo-item {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  margin: 0 6px;
  min-width: 90px;
  max-width: 110px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.25s;
  background-color: white;
  border: 1px solid #eee;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  
  &:first-child {
    margin-left: 12px;
  }
  
  &:last-child {
    margin-right: 12px;
  }
  
  &:hover {
    background-color: #f5f5f5;
    transform: translateY(-2px);
  }
  
  &.grupo-ativo {
    background-color: #d32f2f;
    box-shadow: 0 2px 8px rgba(211, 47, 47, 0.2);
    border-color: #d32f2f;
    
    .grupo-nome {
      color: white;
      font-weight: 600;
    }
    
    .grupo-icone {
      background-color: white;
    }
  }
  
  &.mobile {
    padding: 8px 12px;
    min-width: calc(33.33% - 8px);
    max-width: calc(33.33% - 8px);
    margin: 4px;
    
    &:first-child {
      margin-left: 4px;
    }
    
    &:last-child {
      margin-right: 4px;
    }
    
    .grupo-icone {
      width: 40px;
      height: 40px;
      margin-bottom: 6px;
    }
    
    .grupo-nome {
      font-size: 11px;
    }
  }
}

.grupo-icone {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  
  img {
    max-width: 65%;
    max-height: 65%;
    object-fit: contain;
  }
}

.grupo-nome {
  font-size: 13px;
  text-align: center;
  color: #555;
  font-weight: 500;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-transform: capitalize;
}

@media (max-width: 576px) {
  .grupos-scroll-container {
    padding: 0;
  }
  
  .grupos-inner-container {
    padding: 8px 0;
  }
  
  .scroll-button {
    display: none;
  }
  
  .grupo-item {
    margin: 0 4px;
    
    &:first-child {
      margin-left: 8px;
    }
    
    &:last-child {
      margin-right: 8px;
    }
  }
}
</style>
