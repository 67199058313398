<template>
  <div>
    <div
      :class="`leftSide ` + (openMesasList ? 'leftSideOpen' : 'leftSideClose')"
      @click="
        () => {
          openMenuPlus = false;
        }
      "
    >
      <div
        class="btClose"
        @click="$emit('closeMesa')"
      >
        <b-icon-table />
        <div style="font-size: 10px">Mapa</div>
      </div>
      <div
        v-for="m in mesas"
        :key="`mesa_${Math.random()}${m.id}_`"
        :class="
          `mesaButton ` +
          (mesaObj && mesaObj.cod_mesa == m.cod_mesa ? 'mesaSelected ' : '') +
          (m && m.id && m.id > 0 ? 'mesaOcuped ' : '')
        "
        @click="
          () => {
            // comandaSelected = null;
            $emit('changeMesa', m);
            menuOpened = 'Pedido';
            // joinRoom()
            openMesasList = false;
          }
        "
      >
        {{ m.mesa.replace("Mesa", "") }}
      </div>
    </div>
    <b-overlay
      :show="loading"
      no-wrap
      @click="loading = false"
    >
    </b-overlay>
    <div
      v-if="loading"
      class="p-5 text-center"
    >
      <b-spinner /> aguarde...
    </div>
    <div
      :class="
        `contentMesaInside ` +
        (openMesasList ? 'contentMesaInsideReduce' : 'contentMesaInsideFull') +
        (hideMesaMenu ? ' contentMesaInsideFix ' : '')
      "
      @click="
        () => {
          openMenuPlus = false;
          openMesasList = false;
        }
      "
      v-if="mesaObj"
    >
      <div
        class="fechamentoSolicitado"
        v-if="mesaObj.dt_conta_solicitada"
      >
        <b-icon-calculator /> CONTA SOLICITADA ÁS
        {{ mesaObj.dt_conta_solicitada | moment("DD/MM/YYYY HH:mm") }}
        <b-button
          variant="outline-white"
          size="sm"
          class="ml-sm-4"
          @click="solicitarConta(true, false)"
          >Cancelar</b-button
        >
      </div>
      <!------MEU SUPERIOR MESAS / COMANDAS-------->

      <div
        class="d-flex flex-wrap caixaTopoMesaInsider"
        style="
          width: auto;
          justify-content: space-between;
          height: 50px;

          box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
        "
      >
        <div
          class="d-flex scrollPersonalizadoH jusitfy-center"
          style="overflow-x: auto; height: 50px; flex: 1"
          v-if="mesa && mesaObj && mesaObj.id && menuOpened != 'Config'"
        >
          <div class="lado">
            <mesa-new-mesa-visu
              :mesa="mesa"
              :mesaObj="mesaObj"
              :mesaVisu="mesaVisu"
              :mesas="mesas"
              @select="selectMesaVisu"
            />
          </div>

          <div
            class="lado"
            style="justify-content: flex-end; min-width: 150px"
          >
            <mesa-new-comandas
              v-if="mesa && mesaObj && mesaObj.id"
              :mesaObj="mesaObj"
              :comandaSel="comandaSel"
              @selecionar="selecionarComanda"
              @gerenciarComandas="gerenciarComandas"
              ref="selectComanda"
            />
          </div>
          <div
            class="lado"
            style="justify-content: flex-end"
          >
            <div
              class="mesa"
              @click="trocarMesa"
              style="min-width: 110px"
            >
              <i class="fas fa-exchange-alt mr-1"></i>
              Trocar Mesa
            </div>
          </div>
          <div
            class="lado"
            style="justify-content: flex-end"
          >
            <div
              class="mesa"
              @click="imprimirConta"
              style="min-width: 128px"
            >
              <i class="fas fa-print mr-1"></i>
              Imprimir Conta
            </div>
          </div>
          <div
            class="lado"
            style="justify-content: flex-end"
          >
            <div
              class="mesa"
              @click="cupomFiscal"
              style="min-width: 128px"
            >
              <i class="far fa-file-alt mr-1"></i>
              Cupom Fiscal
            </div>
          </div>
        </div>
        <div class="px-4 pt-2">
          <button
            type="button"
            aria-label="Close"
            class="close"
            style="font-size: 30px"
            @click="$emit('closeMesa')"
          >
            ×
          </button>
        </div>
      </div>
      <!------------          PAGINAS           --------------------------------->
      <div
        :style="`width: auto;
    position: ${isMobile ? 'absolute' : 'absolute'};
    top: ${menuOpened == 'Config' ? '0px' : '55px'};
    left: 0px;
    right: 0px;
    bottom: 0px;`"
      >
        <MesaNewConfig
          v-if="!mesaObj.id || mesaObj.id == 0 || menuOpened == 'Config'"
          :mesaObj="mesaObj"
          :key="'config_' + (mesaObj ? mesaObj.id : 'xxx')"
          :data="data"
          :mesas="mesas"
          :mesaLayout="mesaLayout"
          :getMesaNome="getMesaNome"
          :empresa="empresa"
          :openingComanda="openingComanda"
          @changeMenu="
            (to) => {
              menuOpened = to;
            }
          "
          ref="ConfigPage"
          @close="
            () => {
              $emit('closeMesa');
            }
          "
          @closeSuccess="
            () => {
              $emit('closeMesa');
            }
          "
          @trocarMesaComanda="trocarMesaComanda"
          @selecionarComanda="selecionarComanda"
          @openMesa="openMesa"
          :blocked="blocked"
        />

        <MesaNewPedidos
          v-if="menuOpened == 'Pedido' && mesaObj && mesaObj.id"
          :mesaObj="mesaObj"
          :key="'mesaPEd_' + (mesaObj ? mesaObj.id : 'xxx')"
          :mesaVisu="mesaVisu"
          :data="data"
          @openPedidoEdit="openPedidoEdit"
          :getMesaNome="getMesaNome"
          :addPedido="addPedido"
          :comandaSel="comandaSel"
          @addPgto="addPagamento"
          @fecharMesa="fecharConta"
          @removeATaxa="removeTaxa"
          @trocarMesaComanda="trocarMesaComanda"
          @comprovanteSaida="comprovanteSaida"
          @gotoPG="gotoPG"
          :blocked="blocked"
          :editPagamento="editPagamento"
          :addPagamento="addPagamento"
          :reabrirMesa="Reabrir"
          :empresaSel="empresa"
        />
        <MesaNewConta
          v-if="menuOpened == 'Conta'"
          :mesaObj="mesaObj"
          :mesaVisu="mesaVisu"
          :data="data"
          :addPedido="addPedido"
          :key="'mesaConta_' + (mesaObj ? mesaObj.id : 'xxx')"
          :getMesaNome="getMesaNome"
          :editPagamento="editPagamento"
          :addPagamento="addPagamento"
          :comandaSel="comandaSel"
          @addPgto="addPagamento"
          @removeATaxa="removeTaxa"
          @fecharMesa="fecharConta"
          ref="mesaContaRef"
          @gotoPG="gotoPG"
          :blocked="blocked"
        />
        <mesa-conta-pagamento-form
          v-if="menuOpened == 'addPgto'"
          @cancelar="cancelarPgto()"
          :mesa="mesaToPedido"
          :pagamento="pagamentoSelecionado"
          @carregar="joinRoom"
          :mesaVisu="mesaVisu"
          @removeATaxa="removeTaxa"
          ref="mesaContaRef"
          :getMesaNome="getMesaNome"
          :getMesa="getMesa"
          :comandaSel="comandaSel"
        />
      </div>
    </div>

    <!-------- MENU BOTTOM ------------>
    <div
      class="bottomNavigation"
      @click="
        () => {
          openMenuPlus = false;
        }
      "
      :style="openMesasList ? 'border-start-start-radius:0px;' : ''"
      v-if="mesaObj"
    >
      <div
        class="btNavBt"
        @click.stop="
          () => {
            openMesasList = !openMesasList;
          }
        "
        v-if="
          menuOpened != 'addPgto' &&
          !hideMesaMenu &&
          (!isMobile || menuOpened != 'Config')
        "
      >
        <b-icon-list
          style="vertical-align: middle"
          v-if="!openMesasList"
        />
        <b-icon-x
          style="vertical-align: middle"
          v-if="openMesasList"
        />
        <span style="font-size: 12px">
          {{ mesaObj.mesa }}
        </span>
      </div>

      <div
        :class="`btNavBt ` + (menuOpened == 'Pedido' ? 'btNavBtActive' : '')"
        v-if="
          mesaObj &&
          mesaObj.id &&
          !openMesasList &&
          menuOpened != 'addPgto' &&
          (!isMobile || menuOpened != 'Config')
        "
        @click="menuOpened = 'Pedido'"
      >
        <b-icon-cart /> Pedidos
      </div>
      <div
        :class="`btNavBt ` + (menuOpened == 'Conta' ? 'btNavBtActive' : '')"
        v-if="
          mesaObj &&
          mesaObj.id &&
          !openMesasList &&
          menuOpened != 'addPgto' &&
          (!isMobile || menuOpened != 'Config')
        "
        @click="menuOpened = 'Conta'"
      >
        <b-icon-currency-dollar /> Conta
      </div>
      <div
        :class="`btNavBt ` + (menuOpened == 'Config' ? 'btNavBtActive' : '')"
        v-if="
          mesaObj &&
          mesaObj.id &&
          !mesaObj.dt_fechada &&
          !openMesasList &&
          menuOpened != 'addPgto' &&
          (!isMobile || menuOpened != 'Config') &&
          (!comandaSel || (comandaSel && !comandaSel.dt_fechada))
        "
        @click="menuOpened = 'Config'"
      >
        <b-icon-sliders />
        Config.
      </div>
    </div>
    <!--------BT SALVAR----------->
    <div
      :class="`btPlus btPlusSalvar`"
      @click="
        () => {
          if (menuOpened == 'addPgto') {
            this.$refs.mesaContaRef.addPgto();
          }
          if (menuOpened == 'Config') {
            this.$refs.ConfigPage.salvar();
          }
        }
      "
      v-if="
        mesa &&
        !mesa.dt_fechada &&
        (menuOpened == 'addPgto' || menuOpened == 'Config')
      "
    >
      <b-icon-check />
    </div>
    <!--------BT EXCLUIR----------->
    <div
      :class="`btPlus btPlusExcluir`"
      @click="
        () => {
          if (menuOpened == 'addPgto') {
            this.$refs.mesaContaRef.delPgto(this.pagamentoSelecionado);
          }
        }
      "
      v-if="
        menuOpened == 'addPgto' &&
        pagamentoSelecionado &&
        mesa &&
        !mesa.dt_fechada
      "
    >
      <b-icon-trash />
    </div>
    <!-----------  BT-PLUS-PADRAO   ---------------- -->
    <div
      :class="`btPlus ` + (openMenuPlus ? 'btPlusOpen' : '')"
      @click="
        () => {
          openMenuPlus = !openMenuPlus;
          openMesasList = false;
        }
      "
      v-if="
        mesaObj &&
        mesaObj.id &&
        mesa &&
        !mesa.dt_fechada &&
        !openMesasList &&
        !openMenuPlus &&
        menuOpened != 'addPgto' &&
        menuOpened != 'Config'
      "
    >
      <i
        class="fas fa-list"
        v-if="!openMenuPlus"
      />
      <b-icon-x v-if="openMenuPlus" />
    </div>
    <div
      :class="`btPlus btPlusPrint`"
      @click="imprimirConta"
      v-if="mesaObj && mesaObj.id && hideMesaMenu"
    >
      <b-icon-printer />
    </div>

    <!-----------  BT-CLOSE-OR-CANCEL   ---------------------------------------->
    <div
      v-if="mesa && (menuOpened == 'Config' || mesa.dt_fechada)"
      :class="
        `btPlus btPlusOpen btPlusMenor` +
        (openMesasList || openMenuPlus ? ' btPlusMenorMaior' : '')
      "
      @click="
        () => {
          if (menuOpened == 'Config') {
            if (mesaObj.id > 0) {
              menuOpened = 'Pedido';
            } else {
              mesa.id = null;
              mesaObj.id = null;
              menuOpened = '';
            }
          } else if (menuOpened == 'addPgto') {
            menuOpened = 'Conta';
          } else if (openMenuPlus) {
            openMenuPlus = false;
          } else if (!openMesasList) {
            $emit('closeMesa');
          } else if (openMesasList) {
            openMesasList = false;
          }
        }
      "
    >
      <b-icon-x
        v-if="
          openMenuPlus ||
          openMesasList ||
          menuOpened == 'addPgto' ||
          menuOpened == 'Config'
        "
      />
      <b-icon-door-open
        v-if="
          !openMenuPlus &&
          !openMesasList &&
          menuOpened != 'addPgto' &&
          menuOpened != 'Config'
        "
      />
    </div>
    <!-----------   BT- MAPA - MESAS------------------>

    <div
      v-if="openMesasList"
      :class="
        `btPlus btPlusOpen btPlusMenor btGotoMesas` +
        (openMesasList ? ' btPlusMenorMaior' : '')
      "
      @click="
        () => {
          $emit('closeMesa');
        }
      "
    >
      <b-icon-table class="mr-2" /> Mapa de Mesas
    </div>
    <div
      v-if="openMesasList"
      :class="
        `btPlus btPlusOpen btPlusMenor btGotoMesas btSelComanda` +
        (openMesasList ? ' btPlusMenorMaior' : '')
      "
      @click="
        () => {
          findByComnanda();
        }
      "
    >
      <i class="fa fa-barcode mr-1" /> Buscar Comanda
    </div>

    <!-------WRAPPER-------->
    <div
      class="wrapper"
      @click.prevent="
        () => {
          openMesasList = false;
          openMenuPlus = false;
        }
      "
      v-if="openMesasList"
    ></div>

    <!--------WRAPPER SOMBRA----------->
    <div
      :class="`menuPlusSombra ` + (openMenuPlus ? 'menuPlusSombraOpen' : '')"
      @click="openMenuPlus = false"
    ></div>

    <!--------  MENU PLUS ----------->
    <div
      :class="`menuPlus ` + (openMenuPlus ? 'menuPlusOpen' : '')"
      @click="openMenuPlus = false"
      v-if="
        mesa &&
        !mesa.dt_fechada &&
        (!comandaSel || (comandaSel && !comandaSel.dt_fechada))
      "
    >
      <div
        class="btMenuPlus"
        @click="addPedido"
      >
        <i class="fa fa-pizza-slice mr-1" /> Adicionar Item
      </div>
      <div
        class="btMenuPlus"
        @click="removeTaxa()"
      >
        <i class="fa fa-percent mr-1" />
        Remover Taxa
      </div>
      <div
        class="btMenuPlus"
        @click="addPagamento(null)"
      >
        <i class="fa fa-dollar mr-1" /> Efetuar Pagamento
      </div>
      <div
        class="btMenuPlus"
        @click="solicitarConta(false, false)"
      >
        <i class="fa fa-file-alt mr-1" /> Solicitar Conta
      </div>
      <div
        class="btMenuPlus"
        @click="imprimirConta"
      >
        <i class="fa fa-print mr-1" /> Imprimir Conta
      </div>
      <div
        class="btMenuPlus"
        @click="trocarMesa"
      >
        <i class="fas fa-exchange-alt mr-1"></i> Trocar de Mesa
      </div>
      <div
        class="btMenuPlus"
        v-if="mesa && mesa.uniao && mesa.uniao.length > 0"
        @click="separarMesas"
      >
        <b-icon-code-slash /> Separar Mesas
      </div>
    </div>

    <b-modal
      id="modal-add-pedido"
      size="lg"
      hide-footer
      title="Novo Pedido"
      body-class="p-0 p-sm-3"
      modal-class="m-0 p-0 modalNoPadding"
      @hide="closePedido"
    >
      <pedido
        :mesa="mesaToPedido"
        :PedidoOpen="pedido"
        @close="closePedido"
        :comandaSel="comandaSel"
        :empresaSel="empresa"
      />
    </b-modal>
    <b-modal
      id="modal-add-pgto"
      no-footer
      :title="
        this.pagamentoSelecionado ? 'Editar Pagamento' : 'Adicionar Pagamento'
      "
      hide-footer
      @hide="cancelarPgto"
    >
      <mesa-conta-pagamento-form
        @cancelar="cancelarPgto()"
        :mesa="mesaToPedido"
        :pagamento="pagamentoSelecionado"
        @carregar="joinRoom"
        :mesaVisu="mesaVisu"
        @removeATaxa="removeTaxa"
        ref="mesaContaRef"
        :getMesaNome="getMesaNome"
        :getMesa="getMesa"
        :comanda="comandaSel"
      />
    </b-modal>
    <b-modal
      id="modal-separar-mesas"
      hide-footer
      title="Separar mesas"
    >
      <mesa-new-separar
        :mesaObj="mesaObj"
        :mesa="mesa"
        :getMesaNome="getMesaNome"
      />
    </b-modal>
    <b-modal
      id="modal-trocar-mesa"
      hide-footer
      title="Trocar mesa"
    >
      <mesa-new-trocar-mesa
        :mesaObj="mesaObj"
        :mesa="mesa"
        :getMesaNome="getMesaNome"
        :getMesa="getMesa"
        :mesas="mesas"
        @close="closeTrocarMesa"
      />
    </b-modal>
    <b-modal
      id="modal-trocar-mesa-comanda"
      hide-footer
      title="Transferir comanda de mesa"
    >
      <mesa-new-trocar-mesa-comanda
        :comandaSel="comandaSelTrocar"
        :mesaObj="mesaObj"
        :mesa="mesa"
        :getMesaNome="getMesaNome"
        :getMesa="getMesa"
        :mesas="mesas"
        @close="closeTrocarMesa"
      />
    </b-modal>
    <!-- <b-modal id="modal-find-comanda" hide-footer>
      <mesa-new-comandas-openeds
        :mesas="mesas"
        @mesa="openMesa"
        @comanda="selectComanda"
      />
    </b-modal> -->
    <login-admin
      v-if="loginAdm"
      @result="loginResult"
      :idExterno="mesa && mesa.id ? mesa.id.toString() : ''"
      :tipo="`mesa`"
      :obs="`Retirar/Adicionar Taxa de Serviço (%)`"
    />

    <b-modal
      id="modal-fiscal"
      title="Cupom Fiscal"
      size="xl"
      hide-footer
      v-if="CupomOpen"
    >
      <CupomFiscal
        :MesaContaID="CupomOpen.MesaContaID"
        :CodEmpresa="CupomOpen.cod_empresa"
        @close="closeFiscal"
      />
    </b-modal>
  </div>
</template>

<script>
import LoginAdmin from "../../components/LoginAdmin.vue";

import MesaNewConfig from "./MesaNewConfig.vue";

import MesaNewConta from "./MesaNewConta.vue";

import PedidoLib from "../../libs/Pedido.js";
import MesaContaPagamentoForm from "./MesaContaPagamentoForm.vue";
import MesaNewSeparar from "./MesaNewSeparar.vue";
import MesaNewTrocarMesa from "./MesaNewTrocarMesa.vue";
import MesaNewTrocarMesaComanda from "./MesaNewTrocarMesaComanda.vue";
import MesaNewComandas from "./MesaNewComandas.vue";
import MesaNewMesaVisu from "./MesaNewMesaVisu.vue";
import CupomFiscal from "../../components/Caixas/CupomFiscal.vue";

import MesaLib from "../../libs/MesaLib";
// import moment from "moment";

export default {
  components: {
    MesaNewConfig,
    MesaNewPedidos: () => import("./MesaNewPedidos.vue"),
    MesaNewConta,
    Pedido: () => import("../../components/pedido/Pedido.vue"),
    MesaContaPagamentoForm,
    MesaNewSeparar,
    MesaNewTrocarMesa,
    MesaNewComandas,
    MesaNewMesaVisu,
    LoginAdmin,
    MesaNewTrocarMesaComanda,
    CupomFiscal,
  },
  props: {
    empresa: Object,
    mesas: Array,
    mesa: Object,
    data: String,
    mesaLayout: Object,
    startContent: String,
    openingComanda: String,
    hideMesaMenu: {
      type: Boolean,
      default: false,
    },
    comandaSelected: Object,
    blocked: {
      type: Boolean,
      default: false,
    },
    typeOpen: String,
  },
  sockets: {
    connect() {
      // if (this.mesa.id) this.loading = true;

      this.joinRoom();
    },
    mesaInfo(mesa) {
      console.log("mesa info 2", JSON.parse(JSON.stringify(mesa)));
      if (!mesa) {
        return;
      }
      let ob = Object.assign({}, { ...mesa });
      if (mesa && mesa.pedidos) {
        ob.pedidos = mesa.pedidos.sort((x, b) => {
          return parseInt(x.senha) < parseInt(b.senha) ? -1 : 1;
        });
      } else {
        ob = this.mesa;
      }
      this.mesaObj = ob;
      if (!this.mesaObj || !this.mesaObj.id) {
        this.mesaObj.taxa_servico = this.empresa.configuracoes.taxa_servico;
      }
      try {
        // console.log("cx msea", this.mesaObj, this.empresa);
        if (
          this.empresa &&
          this.empresa.cod_caixa_mesa &&
          (!this.mesaObj || (this.mesaObj && !this.mesaObj.cod_caixa_mesa))
        ) {
          this.mesaObj.cod_caixa_mesa = this.empresa.cod_caixa_mesa;
        }
      } catch (err) {
        err;
      }
      // console.log("mesa montada", ob);
      this.$emit("updateMesa", ob);
      // this.comandaSel = null;
      if (this.comandaSelected) {
        this.comandaSel = this.comandaSelected;
        this.menuPlusOpen = false;
        this.openMesasList = false;
      }

      this.loading = false;

      if (this.voltarParaComanda) {
        this.comandaSel = this.mesaObj.comandas.find(
          (x) => x.id == this.voltarParaComanda
        );
        console.log("BACK TO COMANDA", this.voltarParaComanda);
        this.voltarParaComanda = null;
      }

      this.$forceUpdate();
    },
    mesaClose() {
      //this.$emit("closeMesa");
    },
  },
  mounted() {
    if (this.mesa && !this.mesa.dt_fechada) this.loading = true;

    // console.log("mesa", this.mesa);
    if (this.mesa) {
      this.mesaObj = this.mesa;
      this.menuOpened = "Pedido";
      if (this.mesa && !this.mesa.dt_fechada) this.joinRoom();
    }
    if (this.startContent) {
      this.menuOpened = this.startContent;
    } else {
      if (this.mesa) {
        this.menuOpened = "Pedido";
      } else {
        this.menuOpened = "Config";
      }
    }
    this.comandaSel = null;
    if (this.comandaSelected) {
      this.comandaSel = this.comandaSelected;
      this.menuPlusOpen = false;
      this.openMesasList = false;
    }
    // console.log("AAA", this.menuOpened);
    // console.log("bbb", this.$store.state.auth.user.nivel === 0);
    if (!this.mesa.id) {
      this.loading = false;
    }
    if (this.mesa && this.mesa.id) {
      if (location.href.indexOf("#reserva=") > 0) {
        this.menuOpened = "Config";
        this.$router.replace("/mesas/geral");
      }
    }

    if (this.mesa && this.mesa.id && this.blocked == true) {
      this.mesa.dt_fechada = new Date();
    }

    if (this.mesa && (!this.mesas || this.mesas.length == 0)) {
      ///this.carregarMesas();
    }
    if(this.typeOpen=="2"){
      this.addPedido();
    }
  },
  data() {
    return {
      openMesasList: false,
      openMenuPlus: false,
      mesaObj: null,
      menuOpened: "Config",
      mesaVisu: null,
      pedido: null,
      mesaToPedido: null,
      pagamentoSelecionado: null,
      isMobile: this.$store.state.isMobile,
      comandaSel: null,
      loginAdm: false,
      authAdm: this.$store.state.auth.user.nivel === 0,
      loading: false,
      voltarParaComanda: null,
      gotoMesa: null,
      comandaSelTrocar: null,
      CupomOpen: {
        MesaContaID: null,
        cod_empresa: null,
      },
    };
  },
  watch: {
    mesas: {
      deep: true,
      handler() {
        // console.log("mesas atualizadas");
        if (this.gotoMesa) {
          let m = this.mesas.find((x) => x.cod_mesa == this.gotoMesa.cod_mesa);
          this.$emit("changeMesa", m);
        }
      },
    },
    mesaObj: {
      deep: true,
      handler() {
        // this.joinRoom();
      },
    },
    "$props.mesa": {
      deep: true,
      handler() {
        console.log("MESA ATUALIZOU", this.mesa);

        this.mesaObj = this.mesa;
        // this.$set("mesaObj", this.mesa);
        this.mesaVisu = null;
        if (this.mesa && !this.mesa.dt_fechada) {
          this.joinRoom();
        } else {
          this.loading = false;
        }
        this.$forceUpdate();
        this.menuPlusOpen = false;
        this.openMesasList = false;
      },
    },
    comandaSel: function () {
      this.$forceUpdate();
    },
    comandaSelected: {
      deep: true,
      handler() {
        console.log("AQUI !!!!!!!!!!!!!!!!!!!!!!!!!!");
        this.comandaSel = null;

        if (this.comandaSelected) {
          this.comandaSel = this.comandaSelected;
          this.menuPlusOpen = false;
          this.openMesasList = false;
        }
      },
    },
  },
  computed: {
    pedidos() {
      return this.mesaObj.pedidos.filter((x) =>
        this.mesaVisu ? x.cod_mesa == this.mesaVisu : true
      );
    },
    subtotal() {
      return this.mesaObj.pedidos
        .filter((x) => !x.excluido || x.excluido == 0)
        .filter(
          (x) =>
            (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
            (this.comandaSel ? x.comanda_id == this.comandaSel.id : true)
        )
        .reduce((ret, vl) => {
          ret += vl.vl_total;
          return ret;
        }, 0);
    },
    taxa_servico() {
      return this.comandaSel
        ? this.comandaSel.taxa_servico
        : this.mesaObj.taxa_servico;
    },
    taxa() {
      return this.comandaSel
        ? this.comandaSel.vl_taxa_servico
        : this.mesaObj.vl_taxa_servico;
    },
    total() {
      return this.subtotal + this.taxa;
    },
    pago() {
      return this.mesaObj.pagamentos
        ? this.mesaObj.pagamentos
            .filter(
              (x) =>
                (this.mesaVisu ? x.cod_mesa == this.mesaVisu : true) &&
                (this.comandaSel ? x.comanda_id == this.comandaSel.id : true)
            )
            .reduce((ret, vl) => {
              ret += vl.valor;
              return ret;
            }, 0)
        : 0;
    },
    pagoRateado() {
      return this.mesaObj.pagamentos
        ? this.mesaObj.pagamentos
            .filter((x) => x.ratear == 1)
            .reduce((ret, vl) => {
              ret += vl.valor;
              return ret;
            }, 0)
        : 0;
    },
    restante() {
      return this.total - this.pago;
    },
  },
  methods: {
    openMesa(m) {
      console.log("m", m);
      this.$emit("changeMesa", m);
      this.mesaObj = m;
      this.mesaVisu = null;
      this.joinRoom();
      // this.gotoMesa = m.cod_mesa;
      // this.carregarMesas();
      this.menuOpened = "Pedido";
      if (this.mesas && this.mesas.length > 0) {
        let findMesa = this.mesas.find((x) => x.cod_mesa == m.cod_mesa);
        console.log("find mesa", findMesa);
      }
    },
    async carregarMesas() {
      try {
        let lista = await MesaLib.getMesasAbertasAll(
          this.mesa.cod_empresa,
          "aberta"
        );
        if (lista && (!this.mesas || this.mesas.length <= 0)) {
          this.mesas = lista;
          if (this.gotoMesa) {
            let m = this.mesas.find(
              (x) => x.cod_mesa == this.gotoMesa.cod_mesa
            );
            this.$emit("changeMesa", m);
          }
        }
      } catch (error) {
        console.log("erro ao carregar mesas", error);
      }

      //console.log("mesas", lista);
    },
    gotoPG() {
      if (this.$store.state.isMobile) {
        this.menuOpened = "Conta";
      }
      setTimeout(() => {
        this.addPagamento();
      }, 540);
    },
    comprovanteSaida(item) {
      console.log("solicitando impressao");
      this.$socket.emit("mesas:comprovanteSaida", { mesa: this.mesaObj, item });
    },
    closeFiscal() {
      this.$bvModal.hide("modal-fiscal");
    },
    cupomFiscal() {
      this.CupomOpen.MesaContaID = this.mesaObj.id;
      this.CupomOpen.cod_empresa = this.mesaObj.cod_empresa;
      this.$bvModal.show("modal-fiscal");
    },
    gerenciarComandas() {
      this.$refs.selectComanda.closeComandas();
      this.menuOpened = "Config";
    },
    selectComanda(comanda) {
      this.comandaSel = comanda;
    },
    findByComnanda() {
      this.$bvModal.show("modal-find-comanda");
    },
    loginResult(result) {
      this.loginAdm = false;
      this.authAdm = !result ? false : result ? true : false;
      if (result) {
        this.alterarTaxa();
      }
    },
    solicitarLoginAdm() {
      if (!this.authAdm || this.authAdm == false) {
        this.loginAdm = true;
        return false;
      } else {
        return true;
      }
    },
    selecionarComanda(comanda) {
      console.log("selecionar comanda", comanda);
      this.comandaSel = comanda;
    },
    alterarTaxa() {
      if (!this.mesaObj.dt_fechada) {
        console.log("removendo taxa");
        this.voltarParaComanda = null;
        this.$swal({
          title: "Confirmação",
          text: "Você tem certeza que deseja alterar a taxa de serviço?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then((x) => {
          if (x.isConfirmed) {
            if (this.comandaSel && this.comandaSel.id) {
              this.voltarParaComanda = this.comandaSel.id;
            }

            this.$socket.emit("mesas:alterarTaxa", {
              ...this.mesaObj,
              cod_empresa: this.empresa.cod_empresa,
              cod_usuario: this.user.cod_usuario,
              comandaSel: this.comandaSel,
            });
            this.$emit("carregar");
            setTimeout(() => {
              this.$emit("carregar");
            }, 500);
          }
        });

        this.$emit("carregar");
      }
    },
    async Reabrir() {
      if (
        (this.comandaSel && this.comandaSel.dt_fechada) ||
        (!this.comandaSel && this.mesaObj.dt_fechada)
      ) {
        this.$swal({
          title: "Reabrir",
          text: "Você tem certeza que deseja reabrir a comanda?",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then((x) => {
          if (x.isConfirmed) {
            let obj = {
              ...this.mesaObj,
              cod_empresa: this.empresa
                ? this.empresa.cod_empresa
                : this.mesaObj.cod_empresa,
              cod_usuario: this.user.cod_usuario,
              comandaSel: this.comandaSel,
            };
            console.log("reabrindo", obj);
            this.$socket.emit("mesas:reabrir", obj);

            setTimeout(() => {
              this.$emit("carregar");
              this.joinRoom();
            }, 500);
          }
        });
      }
    },
    async fecharConta() {
      console.log("FECHAR CONTA", this.total, this.pago);
      if (this.total > this.pago) {
        this.$swal(
          "Atenção",
          "Valor pago inferior ao da conta da mesa!",
          "error"
        );
        this.gotoPG();
        return;
      } else {
        console.log("comanda", this.comandaSel);
        if (this.comandaSel && this.comandaSel.dt_fechada) {
          this.$swal({
            title: "Comanda já fechada!",
            icon: "error",
            toast: true,
            showConfirmButton: false,
            timer: 2000,
          });
        }
        if (!this.mesaObj.dt_fechada) {
          console.log("fechar -3");
          let soMesaVisu = false;
          if (this.mesaVisu) {
            soMesaVisu = (
              await this.$bvModal.show({
                title:
                  "Você quer fechar apenas a " +
                  this.getMesaNome(this.mesaVisu) +
                  " ou TODAS?",
                text: "Se você fechar apenas uma mesa a resopnsabilidade do valor restante para pagamento será transferido para as mesas que ainda estiverem abertas?",
                showCancelButton: true,
                confirmButtonText: "Fechar " + this.getMesaNome(this.mesaVisu),
                cancelButton: "Fechar TODOS",
              })
            ).isConfirmed;
          } else {
            soMesaVisu = true;
          }

          if (soMesaVisu) {
            this.$swal({
              title: "Tem certeza que deseja fechar?",
              text: "A operação não poderá ser desfeita!",
              icon: "question",
              showCancelButton: true,
              showConfirmButton: true,
              confirmButtonText: "Fechar",
              cancelButtonText: "Não",
            }).then(async (x) => {
              if (x.isConfirmed) {
                this.mesaObj.cod_usuario =
                  this.$store.state.auth.user.cod_usuario;
                await this.$socket.emit("mesas:fecharMesa", {
                  mesaObj: this.mesaObj,
                  comandaSel: this.comandaSel,
                  cod_usuario: this.$store.state.auth.user.cod_usuario,
                });
                await this.$emit("carregar");
                this.joinRoom();
                // if (!this.comandaSel) {
                this.$emit("closeMesa");
                this.$emit("mesaFechada");

                setTimeout(() => {
                  this.$emit("carregar");
                  this.joinRoom();
                }, 1500);
                // }
                this.$swal({
                  title:
                    (this.comandaSel ? "Comanda" : "Mesa") +
                    " fechada com sucesso!",
                  icon: "success",
                  toast: true,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            });
          }
          // this.$emit("carregar");
        }
      }
    },
    closeTrocarMesa(novaMesa) {
      this.$bvModal.hide("modal-trocar-mesa");
      this.$bvModal.hide("modal-trocar-mesa-comanda");
      //this.$emit("closeMesa",novaMesa)
      if (novaMesa) this.joinRoom();

      this.gotoMesa = novaMesa;
      // this.loading = true;
    },
    trocarMesa() {
      if (!this.mesaObj.dt_fechada) {
        this.$bvModal.show("modal-trocar-mesa");
      }
    },
    trocarMesaComanda(comanda) {
      if (!this.mesaObj.dt_fechada) {
        this.comandaSelTrocar = comanda;
        this.$bvModal.show("modal-trocar-mesa-comanda");
      }
    },
    separarMesas() {
      if (!this.mesaObj.dt_fechada) {
        this.$bvModal.show("modal-separar-mesas");
      }
    },
    solicitarConta(cancelar = false, garcom = true) {
      if (!this.mesaObj.dt_fechada) {
        this.$swal({
          title: cancelar
            ? garcom
              ? "Cancelar solic. Garçom?"
              : "Cancelar solicitação conta?"
            : garcom
            ? "Solicitar Garçom?"
            : "Solicitar conta?",
          icon: "question",
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        }).then((x) => {
          if (x.isConfirmed) {
            this.$socket.emit("mesas:solicitarConta", {
              cancelar,
              garcom,
              conta: !garcom,
              ...this.mesaObj,
            });
          }
        });
      }
    },
    removeTaxa() {
      if (!this.mesaObj.dt_fechada) {
        this.solicitarLoginAdm();
        if (this.authAdm) {
          this.alterarTaxa();
        }
      }
    },
    imprimirConta() {
      this.$socket.emit("mesas:conta", {
        ...this.mesaObj,
        comandaSel: this.comandaSel,
      });
      this.$swal({
        title: "Impressão solicitada!",
        icon: "success",
        toast: true,
        position: "top",
        timer: 1400,
        progress: true,
        showConfirmButton: false,
        grow: true,
      });
    },
    editPagamento(pgto) {
      if (!this.mesaObj.dt_fechada) {
        this.mesaToPedido = this.mesaObj;
        this.mesaToPedido = this.mesas.find((x) => x.cod_mesa == pgto.cod_mesa);
        this.pagamentoSelecionado = pgto;
        //this.menuOpened = "addPgto";
        // this.$bvModal.show('modal-add-pgto')
        console.log("editando", pgto);
        if (pgto.comanda_id) {
          this.comandaSel = this.mesaObj.comandas.find(
            (x) => x.id == pgto.comanda_id
          );
        }

        this.$bvModal.show("modal-add-pgto");
        this.$emit("carregar");
      }
    },
    cancelarPgto() {
      this.mesaToPedido = null;

      if (this.$store.state.isMobile) {
        this.menuOpened = "Conta";
      }

      this.$bvModal.hide("modal-add-pgto");
    },
    addPagamento(comanda) {
      if (!this.mesaObj.dt_fechada) {
        this.voltarParaComanda = null;
        this.mesaToPedido = this.mesaObj;
        if (this.mesaVisu) {
          this.mesaToPedido = this.mesas.find(
            (x) => x.cod_mesa == this.mesaVisu
          );
        } else if (this.mesaObj.mesa_controle_id) {
          this.mesaToPedido = this.mesas.find(
            (x) => x.id == this.mesaObj.mesa_controle_id
          );
        }
        this.pagamentoSelecionado = null;
        this.comandaSelPgto = comanda;
        // this.menuOpened = "addPgto";
        if (comanda) {
          this.voltarParaComanda = comanda.id;
        }

        this.$bvModal.show("modal-add-pgto");
      }
    },
    async openPedidoEdit(pedido) {
      if (!this.mesaObj.dt_fechada) {
        console.log("pedido", pedido);
        this.pedido = (
          await PedidoLib.get({
            cod_pedido: pedido.cod_pedido,
            cod_empresa: this.empresa.cod_empresa,
          })
        ).data[0];
        console.log("pedido:::::", this.pedido);
        this.mesaToPedido = this.mesaObj;

        this.mesaToPedido = this.mesas.find(
          (x) => x.cod_mesa == pedido.cod_mesa
        );

        // console.log(this.pedido);
        this.$bvModal.show("modal-add-pedido");
      }
    },
    closePedido() {
      this.$bvModal.hide("modal-add-pedido");
      this.pedido = null;
      this.mesaToPedido = null;
      if (!this.mesaObj.dt_fechada) {
        this.joinRoom();
      }
    },
    addPedido() {
      if (!this.mesaObj.dt_fechada) {
        this.mesaToPedido = this.mesaObj;
        if (this.mesaVisu) {
          this.mesaToPedido = this.mesas.find(
            (x) => x.cod_mesa == this.mesaVisu
          );
        }
      }
      this.$bvModal.show("modal-add-pedido");
    },
    getMesaNome(cod_mesa) {
      // console.log("mesa", cod_mesa, this.mesas);
      return this.mesas.find((x) => x.cod_mesa === cod_mesa).mesa || "";
    },
    getMesa(cod_mesa) {
      return this.mesas.find((x) => x.cod_mesa === cod_mesa);
    },
    selectMesaVisu(cod_mesa) {
      // console.log("mesavisu", cod_mesa);
      // let m = this.mesas.find((x) => x.cod_mesa === cod_mesa);
      // this.$emit("changeMesa", m);
      this.mesaVisu = cod_mesa;
    },
    joinRoom() {
      // this.loading = true;
      console.log("entrando na mesa", this.mesaObj);
      if (this.mesaObj) {
        this.$socket.emit("mesas:joinRoomMesa", this.mesaObj);
      }
      // if (this.mesa && this.mesa.id) this.loading = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.fechamentoSolicitado {
  padding: 10px;
  background-color: #f9f9f9;
  text-align: center;
}
.mesaJuntadaList {
  display: flex;
  transition: all 0.3s ease;

  div {
    width: 70px;
    height: 40px;
    background-color: #f9f9f9;
    border: 5px solid #f9f9f9;
    border-radius: 10px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  .selected {
    border: 5px solid #999;
  }
}
.wrapper {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  opacity: 0.8;

  position: fixed;
  @media only screen and (min-width: 575px) {
    position: absolute;
  }
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.bottomNavigation {
  position: fixed;
  @media only screen and (max-width: 575px) {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    height: 60px;
  }
  @media only screen and (min-width: 575px) {
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 60px;
    position: absolute;

    flex-direction: column;
    padding: 0px;
    .btNavBt {
      margin-left: 0px !important;
      margin-bottom: 5px !important;
    }
    .btPlusPrint {
      right: auto !important;
      left: 10px !important;
    }
    .btPlus {
      right: inherit !important;
      left: 10px !important;
      bottom: 10px !important;
    }
    // .btPlusMenor{
    //   right: auto !important;
    //   left: 10px !important;
    //   bottom: 0px !important;
    // }
  }

  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  // padding-top: 5px;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  // border-radius: 20px;
  border-right: 1px solid #ccc;

  border-end-end-radius: 0px;
  border-end-start-radius: 0px;

  .btNavBt {
    min-width: 60px;
    height: 60px;
    color: #525252;
    padding-top: 05px;
    font-size: 12px;
    svg {
      font-size: 22px;
    }
    i {
      font-size: 22px;
    }
    text-align: center;
    transition: 0.3s all ease;
    cursor: pointer;
    // border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-left: 20px;
  }
  .btNavBtActive {
    font-size: 12px;
    svg {
      font-size: 22px;
    }
    i {
      font-size: 22px;
    }
    color: #dcff50 !important;
    background-color: #242424;
    // box-shadow: 0px 0px 10px rgba(126, 125, 125, 0.545);
    // @media screen and (min-width: 575px) {
    //   border-right: 2px solid #ccc;
    // }
    // @media screen and (max-width: 575px) {
    //   border-top: 2px solid #ccc;
    // }
    // font-weight: bolder;
    transition: 0.3s all ease;
  }
}
.btPlusPrint {
  right: 120px !important;
  background-color: #181818 !important;
}
.btPlus {
  cursor: pointer;

  position: fixed;
  @media only screen and (min-width: 575px) {
    position: absolute;
    left: 0px;
    right: auto !important;
    bottom: 5px;
  }
  right: 5px;
  bottom: 25px;
  width: 70px;
  height: 70px;
  color: #0c0c0cc4;
  font-size: 40px;

  border-radius: 50%;
  background-color: rgb(68, 178, 73);
  text-align: center;
  transition: 0.3s all ease;
  cursor: pointer;
  z-index: 2;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);
    // bottom: 26px;
  }
}
.btPlusOpen {
  background-color: red;
}
.btPlusMenor {
  right: 15px;
  width: 40px;
  height: 40px;
  bottom: 80px;
  left: 15px;
  font-size: 25px;
  padding-top: 0px;
  background-color: #e80000;
  transition: all 0.3s ease;
  color: #ccc;
  cursor: pointer;
  @media only screen and (max-width: 575px) {
    bottom: 0px;
    left: auto;
    bottom: 10px;
  }
}
.btPlusMenorMaior {
  width: 80px;
  height: 80px;
  font-size: 50px;
  cursor: pointer;
  color: #ccc;
  bottom: 0px;
}
.btPlusSalvar {
  right: 50px;
  @media only screen and (min-width: 575px) {
    right: 50px;
  }
}
.btPlusExcluir {
  right: auto;
  left: 10px;
  background: red;
  width: 60px;
  height: 60px;
  font-size: 30px;
}
.btGotoMesas {
  bottom: 120px;
  font-size: 18px;
  width: 180px;
  border-radius: 20px;
  height: 60px;
  cursor: pointer;
  right: 20px;
  position: absolute;
  left: 110px;
  width: 290px;
  background-color: #333;
}
.btSelComanda {
  bottom: 190px;
}
.menuPlusSombra {
  position: fixed;
  @media only screen and (min-width: 575px) {
    position: absolute;
  }
  cursor: pointer;

  bottom: 0px;
  right: 0px;
  top: 0px;
  left: 0px;
  display: none;

  background: rgba(84, 84, 84, 0.7);

  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.2px);
  -webkit-backdrop-filter: blur(4.2px);
  border: 1px solid rgba(84, 84, 84, 0.09);
  transition: all 0.2s ease;
}
.menuPlusSombraOpen {
  display: block;
  z-index: 1;
}
.menuPlus {
  position: fixed;
  @media only screen and (min-width: 575px) {
    position: absolute;
    top: 0px;
  }
  width: 100%;
  height: 80%;

  //bottom: 60px;
  //right: 30px;
  background-color: transparent;
  z-index: 3;
  transition: all 0.3s ease-in;
  transform-origin: right bottom;
  transform: scale(0);
  padding-left: 50px;
  padding-top: 50px;

  .btMenuPlus {
    width: 100%;
    height: 60px;
    padding: 3px;
    font-size: 20px;
    padding-right: 10px;
    font-weight: bold;
    color: white;
    font-weight: bold;
    transition: all 0.1s ease;
    text-shadow: 0px 0px px rgba(255, 255, 255, 1);

    cursor: pointer;
    &:hover {
      font-size: 15px;
    }
  }
}
.menuPulsClose {
  transform: scale(0);
}
.menuPlusOpen {
  transform: scale(1);
}
.leftSide {
  position: fixed;
  @media only screen and (min-width: 575px) {
    position: absolute;
  }
  top: 0px;
  left: 0px;
  bottom: 0px;
  background-color: #181818;
  color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  transition: 0.2s all ease;
  z-index: 10;

  .btClose {
    flex: 1;
    min-height: 95px;
    display: block;
    font-size: 40px;
    font-weight: bold;

    color: #fff;
    text-align: center;
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid rgba(153, 153, 153, 0.126);
  }
  .mesaButton {
    width: 100%;
    min-height: 55px;
    margin-bottom: 3px;
    color: #f9f9f9;
    text-align: center;
    padding-top: 10px;
    transform: 0.3s all ease;
    font-size: 22px;
    cursor: pointer;
  }
  .mesaSelected {
    background: #fff;
    color: black;
    font-weight: bolder;
  }
  .mesaOcuped {
    color: green;
    font-weight: bolder;
  }
}
.leftSideClose {
  width: 0px;
}
.leftSideOpen {
  width: 90px;
  z-index: 1000;
}
.contentMesaInside {
  background: white;
  padding: 0px;
  position: relative;
  @media only screen and (min-width: 575px) {
    top: 0px;

    bottom: 0px;
  }
  @media only screen and (max-width: 575px) {
    position: fixed;
    top: 0px;

    right: 0px;
    bottom: 60px;
    padding: 5px;
    padding-left: 0px;
    padding-right: 0px;
  }
  //transition: 0.2s all ease;
}
.contentMesaInsideFix {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0px !important;
  overflow-y: hidden;
  height: auto !important;
  min-height: 600px !important;
  padding: 0px !important;
  margin-bottom: 0px !important;
}
.contentMesaInsideFull {
  left: 0px;
  /* height: 100%; */
  bottom: 57px;
  @media screen and (min-width: 575px) {
    bottom: 0px !important;
    left: 60px !important;
  }
  position: absolute;
  right: 0px;
}
.contentMesaInsideReduce {
  left: 0px;
}

.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(620px);
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.35s ease-out;
}

.slide-up-enter-from {
  opacity: 0 !important;
  transform: translateY(30px) !important;
}

.slide-up-leave-to {
  opacity: 0 !important;
  transform: translateY(-30px) !important;
}

.slide-fade-enter-active {
  transition: all 0.1s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(620px);
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 5s ease-out;
}

.slide-left-enter-active,
.slide-left-enter-to {
  transition: all 5s ease-in;

  transform: translateX(-30px) !important;
}

.slide-left-leave-to {
  transition: all 5s ease-out;

  opacity: 0 !important;
  transform: translateX(-30px) !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.lado {
  @media only screen and (min-width: 575px) {
    // flex: 1;
  }
  margin-right: 5px;
  // overflow: hidden;
  // overflow-x: auto;
  align-items: center;
  display: flex;
}
@import "../../assets/scss/mesas.scss";
</style>
